import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../assets/css/style.css';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

function NavbarComponent() {
  const [notifications, setNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState({
    title: '',
    date: '', 
    time: '', 
    content: '',
  });
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);
  const [updateNotification, setUpdateNotification] = useState({
    title: '',
    date: '',
    time: '', 
    content: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notificationCount, setnotificationCount] = useState(0);
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [addErrorMessage, setAddErrorMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteNotificationId, setDeleteNotificationId] = useState(null);
  const [navbarItems, setNavbarItems] = useState([]);
  const [newNavbarItem, setNewNavbarItem] = useState({
    name: '',
    link: '',
    position: '',
  });
  const [updateNavbarItem, setUpdateNavbarItem] = useState({
    id: null,
    name: '',
    link: '',
    position: ''
  });
  const [selectedNavbarItemId, setSelectedNavbarItemId] = useState(null);
  const [showDeleteNavbarPopup, setShowDeleteNavbarPopup] = useState(false);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewNavbarItem({ ...newNavbarItem, [name]: value });
};

const handleSubmit = async (e) => {
  e.preventDefault();

  // Validate the input fields
  if (!newNavbarItem.name.trim() || !newNavbarItem.link.trim() || !newNavbarItem.position.trim()) {
    setAddErrorMessage("Name, Link, and Position are required to add a navbar item.");
    setTimeout(() => {
      setAddErrorMessage("");
    }, 5000);
    return;
  }

  try {
    // Send a POST request to the add navbar item endpoint
    const response = await axios.post(
      'https://backend.kbascontracting.com/navbar/add', // Update this URL to match your API endpoint
      newNavbarItem
    );

   

if (response.status === 200 || response.status === 201) {
console.log('Navbar item added successfully:', response.data);
fetchData(); // Reload the navbar items to reflect the new addition
setNewNavbarItem({
name: '',
link: '',
position: ''
});
setAddSuccessMessage("Navbar item added successfully");
setTimeout(() => {
setAddSuccessMessage("");
}, 5000);
}
} catch (error) {
console.error('Error adding navbar item:', error);
setAddErrorMessage("Failed to add navbar item. Please try again.");
setTimeout(() => {
setAddErrorMessage("");
}, 5000);
}
};
  
  
  

  const fetchData = async () => {
    try {
      const response = await axios.get('https://backend.kbascontracting.com/navbar/list');
      setNavbarItems(response.data.data);
      setnotificationCount(response.data.data.length);
      setLoading(false);
      setError(null);
    } catch (error) {
      console.error(`Error getting notifications: ${error}`);
      setLoading(false);
      setError('Error fetching notifications. Please try again later.');
    }
  };

 const handleDeleteNavbar = (id) => {
  setSelectedNavbarItemId(id);
  setShowDeleteNavbarPopup(true);
};

const performDeleteNavbar = async () => {
  try {
    const response = await axios.delete(`https://backend.kbascontracting.com/navbar/delete/${selectedNavbarItemId}`);
    if (response.status === 200) {
      const updatedNavbarItems = navbarItems.filter(item => item.id !== selectedNavbarItemId);
      setNavbarItems(updatedNavbarItems);
      setShowDeleteNavbarPopup(false);
    } else {
      console.error('Failed to delete navbar item');
    }
  } catch (error) {
    console.error('Error deleting navbar item:', error);
  }
};



// Replace fetchBackgrounds with fetchNotifications in useEffect and elsewhere as needed

  
const handleUpdate = (navbarItem) => {
  console.log("Updating navbar item:", navbarItem);
  if (navbarItem && 'position' in navbarItem) {
  setSelectedNavbarItemId(navbarItem.id);
  setUpdateNavbarItem({
    id: navbarItem.id,
    name: navbarItem.name,
    link: navbarItem.link,
    position: navbarItem.position.toString(), // Assuming position is a number
  });
  setShowUpdateForm(true);
} else {
  console.error("Navbar item does not have a position property");
  // Handle the error accordingly
}
};


const handleNavbarItemUpdate = async (e) => {
  e.preventDefault();

  // Check if all fields are filled
  if (!updateNavbarItem.name.trim() || !updateNavbarItem.link.trim() || !updateNavbarItem.position.trim()) {
    setUpdateErrorMessage("Name, Link, and Position are required.");
    setTimeout(() => {
      setUpdateErrorMessage("");
    }, 5000);
    return;
  }

  try {
    const response = await axios.put(
      `https://backend.kbascontracting.com/navbar/update/${selectedNavbarItemId}`,
      updateNavbarItem
    );
    if (response.status === 200) {
      console.log('Navbar item updated successfully:', response.data);
      fetchData(); // Reload data
      setUpdateNavbarItem({ id: null, name: '', link: '', position: '' });
      setShowUpdateForm(false);
      setUpdateSuccessMessage("Navbar item updated successfully");
      setTimeout(() => {
        setUpdateSuccessMessage("");
      }, 5000);
    }
  } catch (error) {
    console.error('Error updating navbar item:', error);
    setUpdateErrorMessage("Failed to update navbar item. Please try again.");
    setTimeout(() => {
      setUpdateErrorMessage("");
    }, 5000);
  }
};

  

 


  

  useEffect(() => {
    fetchData();
    if (showUpdateForm) {
      // Focus on the input field when the form is shown
      const inputField = document.getElementById('update-notification-title');
      if (inputField) {
        inputField.focus();
      }
    }
  }, [showUpdateForm]);
  
  useEffect(() => {
    if (showUpdateForm) {
      // Assuming you want to focus on the 'name' input field when editing
      const nameInputField = document.getElementById('edit-navbar-name');
      if (nameInputField) {
        nameInputField.focus();
      }
    }
  }, [showUpdateForm]);
  
  

  return (
    <>
      <div className="content">
      <Form onSubmit={handleSubmit}>
  <Row>
    <Col md="7">
      <FormGroup>
        <label>Name:</label>
        <Input
          type="text"
          name="name"
          value={newNavbarItem.name}
          onChange={handleInputChange}
        />
      </FormGroup>
    </Col>
    <Col md="7">
      <FormGroup>
        <label>Link:</label>
        <Input
          type="text"
          name="link"
          value={newNavbarItem.link}
          onChange={handleInputChange}
        />
      </FormGroup>
    </Col>

   <Col md="7">
      <FormGroup>
        <label>Position:</label>
        <Input
          type="number"
          name="position"
          value={newNavbarItem.position}
          onChange={handleInputChange}
        />
      </FormGroup>
    </Col>
  </Row>
  <Row>
    <Col md="12">
      <div className="update ml-auto mr-auto">
        <Button className="btn-round" color="primary" type="submit">
          Add Navbar Item
        </Button>
        {addSuccessMessage && (
          <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
            {addSuccessMessage}
          </div>
        )}
        {addErrorMessage && (
          <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
            {addErrorMessage}
          </div>
        )}
      </div>
    </Col>
  </Row>
</Form>
        <CardHeader>
          <CardTitle tag="h4">Navbar Items List</CardTitle>
        </CardHeader>
        <CardFooter>
          <div>Navbar Items Total: {navbarItems.length}</div>
        </CardFooter>
        {loading ? (
          <p>Loading notifications...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            <Table>
              <thead>
                <tr>
                  <th>name</th>
                  <th>link</th>
                  <th>position</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
              {navbarItems.map((item) => (
  <tr key={item.id}>
    <td>{item.name ? item.name : 'N/A'}</td>
    <td>{item.link ? item.link : 'N/A'}</td>
    <td>{typeof item.position === 'number' ? item.position : 'N/A'}</td>
    <td>
    <Button color="success" onClick={() => handleUpdate(item)}>Update</Button>
      {' '}
      <Button color="danger" onClick={() => handleDeleteNavbar(item.id)}>Delete</Button>    </td>
  </tr>
))}
              </tbody>
            </Table>
            {showUpdateForm && (
    <Form onSubmit={handleNavbarItemUpdate} style={{ marginTop: '100px' }}>
      <Row>
        <Col md="7">
        <FormGroup>
  <label>Name:</label>
  <Input
    type="text"
    name="name"
    value={updateNavbarItem.name}
    id="edit-navbar-name" // Add this ID
    onChange={(e) =>
      setUpdateNavbarItem({
        ...updateNavbarItem,
        name: e.target.value,
      })
    }
  />
</FormGroup>

    </Col>
    <Col md="7">
      <FormGroup>
        <label>Link:</label>
        <Input
          type="text"
          name="link"
          value={updateNavbarItem.link}
          id="edit-icon-name"
          onChange={(e) =>
            setUpdateNavbarItem({
              ...updateNavbarItem,
              link: e.target.value,
            })
          }
        />
      </FormGroup>
    </Col>
    <Col md="7">
      <FormGroup>
        <label>Position:</label>
        <Input
          type="number"
          name="position"
          value={updateNavbarItem.position}
          onChange={(e) =>
            setUpdateNavbarItem({
              ...updateNavbarItem,
              position: e.target.value,
            })
          }
        />
      </FormGroup>
    </Col>
  </Row>
  <Row>
    <Col md="12">
      <Button type="submit" color="primary">Update Navbar Item</Button>
      <Button color="secondary" onClick={() => setShowUpdateForm(false)}>Cancel</Button>
    </Col>
  </Row>
  {updateSuccessMessage && (
    <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
      {updateSuccessMessage}
    </div>
  )}
  {updateErrorMessage && (
    <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
      {updateErrorMessage}
    </div>
  )}
</Form>

)}
          </>
        )}
        <Modal isOpen={showDeleteNavbarPopup} toggle={() => setShowDeleteNavbarPopup(false)}>
<ModalHeader toggle={() => setShowDeleteNavbarPopup(false)}>Confirm Delete</ModalHeader>
<ModalBody>
Are you sure you want to delete this navbar item?
</ModalBody>
<ModalFooter>
<Button color="danger" onClick={performDeleteNavbar}>Delete</Button>{' '}
<Button color="secondary" onClick={() => setShowDeleteNavbarPopup(false)}>Cancel</Button>
</ModalFooter>
</Modal>
</div>
</>
);
}

export default NavbarComponent;
