import Dashboard from "views/Dashboard.js";
import SocialMedia from "views/SocialMedia.js";
import HomePage from "views/HomePage";
import Footer from "views/Footer";
import InfoBar from "views/InfoBar";
import Adduser from "views/Adduser";
import Navbar from "views/Navbar";






var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: (
    <Dashboard />
    ),
    layout: "/admin",
  },
  {
    path: "/SocialMedia",
    name: "Social Media",
    icon: "nc-icon nc-chat-33",
    component: <SocialMedia />,
    layout: "/admin",

  },
  {
    path: "/InfoBar",
    name: "Info Bar",
    icon: "nc-icon nc-single-copy-04",
    component: <InfoBar />,
    layout: "/admin",

  },

  {
    path: "/Navbar",
    name: "Navbar",
    icon: "nc-icon nc-bullet-list-67",
    component: <Navbar />,
    layout: "/admin",
  },

  {
    path: "/HomePage",
    name: "Home Page",
    icon: "nc-icon nc-tv-2", // assuming 'nc-home' is the class for a home icon
    component: <HomePage />,
    layout: "/admin",
},

  {
    path: "/Footer",
    name: "Footer",
    icon: "nc-icon nc-paper",
    component: <Footer />,
    layout: "/admin",

  },







  {
    
    path: "/addUser",
    name: "Add user",
    icon: "nc-icon nc-simple-add",
    component: <Adduser />,
    layout: "/admin",

  },

];

// Check the user's role and filter out restricted routes
const userRole = localStorage.getItem("userRole");
if (userRole === "standard") {
  routes = routes.filter(
    (route) =>
      route.path !== "/user-management" && route.path !== "/UserPage"
  );
}

export default routes;
