import React ,{useEffect,useState}from "react";

import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form, FormGroup, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import "../assets/css/style.css";


function Events() {
    const [infoBarevents, setInfoBarevents] = useState([]);
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    title: "",
    date: "",
    content: "",
  });
  const [eventImage, setEventImage] = useState(null);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [updateEvent, setUpdateEvent] = useState({
    email: "",
    logo: "",
    phone: "",
    icon: "", 
  })
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [InfoBarCount, setInfoBarCount] = useState(0); 
  const [addError, setAddError] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [AddRequired, setAddRequired] = useState("");
  const [UpdateRequired, setUpdateRequired] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteEventId, setDeleteEventId] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);





  
  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };
  

  
  

  
  
  
  

  const fetchData = async () => {
    try {
        const response = await axios.get("https://backend.kbascontracting.com/InfoBar/list");
        if (response.data.success) {
            setInfoBarevents(response.data.data); // This should now include the 'id'
            setInfoBarCount(response.data.data.length);
        } else {
            console.error("Failed to fetch events:", response.data.message);
        }
    } catch (error) {
        console.error("Error fetching events:", error);
        setError("Error fetching data. Please try again later.");
    }
    setLoading(false);
};


  


  
  
  

  const handleUpdate = (event) => {
    console.log("Selected Event for update:", event);
    setSelectedEventId(event.id);
    setUpdateEvent({
        email: event.email,
        phone: event.phone,
        logo: event.logo,
        icon: event.icon,
    });
    setShowUpdateForm(true);
};

  
  


  // Function to handle the main image change
const handleMainImageChange = (e) => {
  if (e.target.files[0]) {
      setEventImage(e.target.files[0]);
  }
};

// Function to handle the additional images change
const handleAdditionalImagesChange = (e) => {
  if (e.target.files[0]) {
      setUpdateEvent(prev => ({ ...prev, icon: e.target.files[0] }));
  }
};


const handleEventUpdate = async (e) => {
  e.preventDefault();
  if (!selectedEventId) {
      console.error("No event selected for update");
      return;
  }

  // Validation checks
  if (!updateEvent.email || !updateEvent.phone) {
    setUpdateRequired("Email and Phone are required.");
    setTimeout(() => {
      setUpdateRequired("");
    }, 5000);
    return;
  }

  const formData = new FormData();
  formData.append("email", updateEvent.email);
  formData.append("phone", updateEvent.phone);

  // Check if new logo or icon images are provided
  const isLogoUpdated = eventImage && eventImage instanceof File;
  const isIconUpdated = updateEvent.icon && updateEvent.icon instanceof File;

  // Append new images if provided
  if (isLogoUpdated) {
    if (!isValidImageExtension(eventImage.name)) {
      setUpdateError("Invalid main image file extension. Please upload .WEBP, .GIF, .PNG, .JPG, or .JPEG");
      setTimeout(() => setUpdateError(""), 5000);
      return;
    }
    formData.append("logo", eventImage);
  }

  if (isIconUpdated) {
    if (!isValidImageExtension(updateEvent.icon.name)) {
      setUpdateError("Invalid main image file extension. Please upload .WEBP, .GIF, .PNG, .JPG, or .JPEG");
      setTimeout(() => setUpdateError(""), 5000);
      return;
    }
    formData.append("icon", updateEvent.icon);
  }

  // If no new images are provided, append the existing image paths
  if (!isLogoUpdated && updateEvent.logo) {
    formData.append("currentLogo", updateEvent.logo);
  }
  if (!isIconUpdated && updateEvent.icon) {
    formData.append("currentIcon", updateEvent.icon);
  }

  // Send PUT request to update the event
  try {
    const response = await axios.put(`https://backend.kbascontracting.com/InfoBar/update/${selectedEventId}`, formData, {
      headers: {
          "Content-Type": "multipart/form-data",
      },
    });

    console.log("Event updated successfully:", response.data);
    fetchData(); // Refresh event list
    setUpdateSuccessMessage("Event updated successfully");
    setTimeout(() => {
      setUpdateSuccessMessage("");
    }, 5000);

    // Reset form fields and state related to updating an event
    setUpdateEvent({ email: "", phone: "", icon: "", logo: "" });
    setEventImage(null);
    setSelectedEventId(null);
  } catch (error) {
    console.error("Error updating event:", error);
    setUpdateError("Error updating event");
    setTimeout(() => {
      setUpdateError("");
    }, 5000);
  }
};

  
  
  
  



  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (showUpdateForm) {
      // Assuming you want to focus on the 'title' input field when editing
      const titleInputField = document.getElementById('edit-event-title');
      if (titleInputField) {
        titleInputField.focus();
      }
    }
  }, [showUpdateForm]); 
  
  return (
    <>
    
      <div className="content">




   <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Info Bar List</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                    <tr>
                  <th>Logo</th>
                  <th>Icon</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Actions</th>
                </tr>
            </thead>
            <tbody>
            {infoBarevents.map((event, index) => (
    <tr key={index}>
                    <td><img src={`https://backend.kbascontracting.com/${event.logo}`} alt="Logo" style={{ width: '100px' }} /></td>
                    <td><img src={`https://backend.kbascontracting.com/${event.icon}`} alt="Icon" style={{ width: '50px' }} /></td>
                    <td>{event.phone}</td>
                    <td>{event.email}</td>
                    <td>

        <Button color="success" onClick={() => handleUpdate(event)}>Update</Button>
      </td>
                  </tr>
                ))}
              </tbody>

                  </Table>
                </CardBody>
              </Card>

     

            </Col>
          </Row>
        </div>
      

      {/* Display the update form when showUpdateForm is true */}
      {showUpdateForm && (
  <div className="content">
    <Form onSubmit={handleEventUpdate}>
      {/* Add your update form fields here */}
      <Row>
        <Col md="7">
          <FormGroup>
            <label>email:</label>
            <Input
              type="text"
              name="title"
              
              value={updateEvent.email}
              onChange={(e) =>
                setUpdateEvent({ ...updateEvent, email: e.target.value })
              }
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>phone:</label>
            <Input
              type="text"
              name="date"
              value={updateEvent.phone}   
              id="edit-event-title"                        
               onChange={(e) =>
                setUpdateEvent({ ...updateEvent, phone: e.target.value })
              }
            />
          </FormGroup>
        </Col>
      </Row>
      

        <Row>
        <Col md="7">
  <FormGroup>
    <label>Current Logo:</label>
    {updateEvent.logo && (
      <div>
        <img
          src={`https://backend.kbascontracting.com/${updateEvent.logo}`}
          alt="Current Event"
          style={{ maxWidth: '200px', marginBottom: '10px' }}
        />
      </div>
    )}
    <label>New Logo (optional):</label>
    <Input type="file" name="main_image"  onChange={handleMainImageChange} />
  </FormGroup>
</Col>
<Col md="7">
  <FormGroup>
    <label>Current Icon:</label>
    {updateEvent.icon && (
    <div>
        <img
          src={`https://backend.kbascontracting.com/${updateEvent.icon}`}
          alt="Current Event"
          style={{ maxWidth: '100px', marginRight: '10px', marginBottom: '10px' }}
        />
     
    </div>
    )}
    <label>New Icon (optional):</label>
    <Input type="file" name="additional_images"  onChange={handleAdditionalImagesChange}/>
  </FormGroup>
</Col>

      </Row>
      {/* Submit and Cancel buttons */}
      <Row>
        <Col md="12">
          <div className="update ml-auto mr-auto">
            <Button className="btn-round" color="primary" type="submit">
              Update Event
            </Button>{" "}
            <Button
              className="btn-round"
              color="secondary"
              onClick={() => {
                setShowUpdateForm(false);
                setSelectedEventId(null);
                setUpdateEvent({
                    email: "",
                    phone: "",
                    icon: "",
                    logo: "",
                });
              }}
            >
              Cancel
            </Button>
            {updateError && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {updateError}
              </div>
            )}
            {updateSuccessMessage && (
              <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                {updateSuccessMessage}
              </div>
            )}
            {UpdateRequired && (
              <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                {UpdateRequired}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  </div>
)}
</div>

    </>
  );
}

export default Events;