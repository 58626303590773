// Terms.js (Front-End)

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";

function Footer() {
  const [terms, setTerms] = useState([]);
  const [newTerm, setNewTerm] = useState({
    title: "",
    description: "",
  });
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedTermId, setSelectedTermId] = useState(null);
  const [updateTerm, setUpdateTerm] = useState({
    title: "",
    description: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [termCount, setTermCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");




  const [terms1, setTerms1] = useState([]);
  const [newTerm1, setNewTerm1] = useState({
    title: "",
    description: "",
  });
  const [showUpdateForm1, setShowUpdateForm1] = useState(false);
  const [selectedTermId1, setSelectedTermId1] = useState(null);
  const [updateTerm1, setUpdateTerm1] = useState({
    title: "",
    description: "",
  });
  const [loading1, setLoading1] = useState(true);
  const [error1, setError1] = useState(null);
  const [termCount1, setTermCount1] = useState(0);
  const [successMessage1, setSuccessMessage1] = useState("");
  const [updateErrorMessage1, setUpdateErrorMessage1] = useState("");







  const [section, setsection] = useState([]);
  const [newsection, setNewsection] = useState({
    iconImage: null,
    title: "",
  });
  const [showUpdatesection, setShowUpdatesection] = useState(false);
  const [selectedsection1, setSelectedsection] = useState(null);
  const [updatesection, setUpdatesection] = useState({
    iconImage: null,
    title: "",
  });
  const [loadingsection, setLoadingsection] = useState(true);
  const [errorsection, setErrorsection] = useState(null);
  const [termCountsection, setTermCountsection] = useState(0);
  const [successMessagesection, setSuccessMessagesection] = useState("");
  const [updateErrorMessagesection, setUpdateErrorMessagesection] = useState("");







  const [news, setnews] = useState([]);
  const [newnews, setNewnews] = useState({
    title: "",
    
  });
  const [showUpdatenews, setShowUpdatenews] = useState(false);
  const [selectednewsId, setSelectednewsId] = useState(null);
  const [updatenews, setUpdatenews] = useState({
    title: "",
    
  });
  const [loadingnews, setLoadingnews] = useState(true);
  const [errornews, setErrornews] = useState(null);
  const [newsCount, setnewsCount] = useState(0);
  const [successMessagenews, setSuccessMessagenews] = useState("");
  const [updateErrorMessagenews, setUpdateErrorMessagenews] = useState("");






  const [Subscribe, setSubscribe] = useState([]);
  const [newSubscribe, setNewSubscribe] = useState({
    title: "",
    description: "",
  });
  const [showUpdateSubscribe, setShowUpdateSubscribe] = useState(false);
  const [selectedSubscribeId, setSelectedSubscribeId] = useState(null);
  const [updateSubscribe, setUpdateSubscribe] = useState({
    title: "",
    description: "",
  });
  const [loadingSubscribe, setLoadingSubscribe] = useState(true);
  const [errorSubscribe, setErrorSubscribe] = useState(null);
  const [SubscribeCount, setSubscribeCount] = useState(0);
  const [successMessageSubscribe, setSuccessMessageSubscribe] = useState("");
  const [updateErrorMessageSubscribe, setUpdateErrorMessageSubscribe] = useState("");



  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };



  const fetchData5 = async () => {
    try {
      const response = await axios.get("https://backend.kbascontracting.com/Subscribe/list");
      if (response.data && response.data.success) {
        setSubscribe(response.data.data); // Wrapping the object in an array
        setSubscribeCount(1); // Since it'sa single object
      } else {
        console.error("Unexpected response format:", response.data);
        // Handle this situation appropriately
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setLoadingSubscribe(false);
      setErrorSubscribe("Error fetching data. Please try again later.");
    }
  };
  
  



  const handleUpdate7 = (term7) => {
    setSelectedSubscribeId(term7.id);
    setUpdateSubscribe({
      title: term7.title,
      description: term7.description,
    });
    setShowUpdateSubscribe(!showUpdateSubscribe);
    };

    const handleTermUpdate7 = async (e) => {
      e.preventDefault();
    
      // Validation: Check if title and description are filled
      if (!updateSubscribe.title.trim() || !updateSubscribe.description.trim()) {
        setUpdateErrorMessageSubscribe("Title and description are required.");
        setTimeout(() => {
          setUpdateErrorMessageSubscribe("");
        }, 5000);
    
        return;
      }
    
      try {
        const response = await axios.put(
          `https://backend.kbascontracting.com/Subscribe/update/${selectedSubscribeId}`,
          updateSubscribe
        );
    
        if (response.status === 200) {
          console.log("Term updated successfully:", response.data);
          fetchData5();
          setUpdateSubscribe({ title: "", description: "" });
          setSelectedSubscribeId(null);
          setSuccessMessageSubscribe("Term updated successfully");
          setUpdateErrorMessageSubscribe(""); // Clear any existing error message
          setTimeout(() => {
            setSuccessMessageSubscribe("");
          }, 5000);
        }
      } catch (error) {
        console.error("Error updating term:", error);
        setUpdateErrorMessageSubscribe("Failed to update term. Please try again.");
      }
    };
    


  useEffect(() => {
    fetchData5();
    if (showUpdateSubscribe) {
      // Assuming the first input field has an id 'update-title'
      document.getElementById('update-title').focus();
    }
  }, [showUpdateSubscribe]);








  const fetchData4 = async () => {
    try {
      const response = await axios.get("https://backend.kbascontracting.com/News/list");
      if (response.data && response.data.success) {
        setnews(response.data.data); // Wrapping the object in an array
        setnewsCount(1); // Since it'sa single object
      } else {
        console.error("Unexpected response format:", response.data);
        // Handle this situation appropriately
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setLoadingnews(false);
      setErrornews("Error fetching data. Please try again later.");
    }
  };
  
  



  const handleUpdate4 = (term4) => {
    setSelectednewsId(term4.id);
    setUpdatenews({
      title: term4.title,
      
    });
    setShowUpdatenews(!showUpdatenews);
    };

    const handleTermUpdate4 = async (e) => {
      e.preventDefault();
    
      // Validation: Check if title and description are filled
      if (!updatenews.title.trim() ) {
        setUpdateErrorMessagenews("Title and description are required.");
        setTimeout(() => {
          setUpdateErrorMessagenews("");
        }, 5000);
    
        return;
      }
    
      try {
        const response = await axios.put(
          `https://backend.kbascontracting.com/News/update/${selectednewsId}`,
          updatenews
        );
    
        if (response.status === 200) {
          console.log("Term updated successfully:", response.data);
          fetchData4();
          setUpdatenews({ title: "" });
          setSelectednewsId(null);
          setSuccessMessagenews("Term updated successfully");
          setUpdateErrorMessagenews(""); // Clear any existing error message
          setTimeout(() => {
            setSuccessMessagenews("");
          }, 5000);
        }
      } catch (error) {
        console.error("Error updating term:", error);
        setUpdateErrorMessagenews("Failed to update term. Please try again.");
      }
    };
    


  useEffect(() => {
    fetchData4();
    if (showUpdatenews) {
      // Assuming the first input field has an id 'update-title'
      document.getElementById('update-title').focus();
    }
  }, [showUpdatenews]);









  const fetchData3 = async () => {
    try {
      const response = await axios.get("https://backend.kbascontracting.com/NewsSection/list");
      if (response.data && response.data.success) {
        setsection(response.data.data); // Wrapping the object in an array
        setTermCountsection(1); // Since it'sa single object
      } else {
        console.error("Unexpected response format:", response.data);
        // Handle this situation appropriately
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setLoadingsection(false);
      setErrorsection("Error fetching data. Please try again later.");
    }
  };
  
  
  const handleFileChange = (e) => {
    setUpdatesection({ ...updatesection, file: e.target.files[0] });
  };
  


  const handleUpdate2 = (section) => {
    setSelectedsection(section.id);
    setUpdatesection({
      title: section.title,
      mainImage: section.mainImage,
    });
    setShowUpdatesection(!showUpdatesection);
    };

    const handleTermUpdate2 = async (e) => {
      e.preventDefault();
    
      // Validation: Check if title and description are filled
      if (!updatesection.title.trim() ) {
        setUpdateErrorMessagesection("Title and description are required.");
        setTimeout(() => {
          setUpdateErrorMessagesection("");
        }, 5000);
    
        return;
      }

      const formData = new FormData();
      formData.append('title', updatesection.title);
    
      // Append the file only if it exists
      if (updatesection.file) {
        // Validate the image file extension
        if (!isValidImageExtension(updatesection.file.name)) {
          setUpdateErrorMessagesection("Invalid image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
          setTimeout(() => {
            setUpdateErrorMessagesection("");
          }, 5000);
          return;
        }
    
        formData.append('mainImage', updatesection.file);
      }
      try {
        const response = await axios.put(
          `https://backend.kbascontracting.com/NewsSection/update/${selectedsection1}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
    
        if (response.status === 200) {
          console.log("Term updated successfully:", response.data);
          fetchData3();
          setUpdatesection({ title: "", description: "" });
          setSelectedsection(null);
          setSuccessMessagesection("Term updated successfully");
          setUpdateErrorMessagesection(""); // Clear any existing error message
          setTimeout(() => {
            setSuccessMessagesection("");
          }, 5000);
        }
      } catch (error) {
        console.error("Error updating term:", error);
        setUpdateErrorMessagesection("Failed to update term. Please try again.");
      }
    };
    


  useEffect(() => {
    fetchData3();
    if (showUpdatesection) {
      // Assuming the first input field has an id 'update-title'
      document.getElementById('update-title').focus();
    }
  }, [showUpdatesection]);



  

  const fetchData = async () => {
    try {
      const response = await axios.get("https://backend.kbascontracting.com/AboutFooter/list");
      if (response.data && response.data.success) {
        setTerms(response.data.data); // Wrapping the object in an array
        setTermCount(1); // Since it'sa single object
      } else {
        console.error("Unexpected response format:", response.data);
        // Handle this situation appropriately
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setLoading(false);
      setError("Error fetching data. Please try again later.");
    }
  };
  
  



  const handleUpdate = (term) => {
    setSelectedTermId(term.id);
    setUpdateTerm({
      title: term.title,
      description: term.description,
    });
    setShowUpdateForm(!showUpdateForm);
    };

    const handleTermUpdate = async (e) => {
      e.preventDefault();
    
      // Validation: Check if title and description are filled
      if (!updateTerm.title.trim() || !updateTerm.description.trim()) {
        setUpdateErrorMessage("Title and description are required.");
        setTimeout(() => {
          setUpdateErrorMessage("");
        }, 5000);
    
        return;
      }
    
      try {
        const response = await axios.put(
          `https://backend.kbascontracting.com/AboutFooter/update/${selectedTermId}`,
          updateTerm
        );
    
        if (response.status === 200) {
          console.log("Term updated successfully:", response.data);
          fetchData();
          setUpdateTerm({ title: "", description: "" });
          setSelectedTermId(null);
          setSuccessMessage("Term updated successfully");
          setUpdateErrorMessage(""); // Clear any existing error message
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
        }
      } catch (error) {
        console.error("Error updating term:", error);
        setUpdateErrorMessage("Failed to update term. Please try again.");
      }
    };
    


  useEffect(() => {
    fetchData();
    if (showUpdateForm) {
      // Assuming the first input field has an id 'update-title'
      document.getElementById('update-title').focus();
    }
  }, [showUpdateForm]);






  const fetchData1 = async () => {
    try {
      const response = await axios.get("https://backend.kbascontracting.com/ServicesFooter/list");
      if (response.data && response.data.success) {
        setTerms1(response.data.data); // Wrapping the object in an array
        setTermCount1(1); // Since it'sa single object
      } else {
        console.error("Unexpected response format:", response.data);
        // Handle this situation appropriately
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setLoading1(false);
      setError1("Error fetching data. Please try again later.");
    }
  };
  
  



  const handleUpdate1 = (term1) => {
    setSelectedTermId1(term1.id);
    setUpdateTerm1({
      title: term1.title,
      description: term1.description,
    });
    setShowUpdateForm1(!showUpdateForm1);
    };

    const handleTermUpdate1 = async (e) => {
      e.preventDefault();
    
      // Validation: Check if title and description are filled
      if (!updateTerm1.title.trim() || !updateTerm1.description.trim()) {
        setUpdateErrorMessage1("Title and description are required.");
        setTimeout(() => {
          setUpdateErrorMessage1("");
        }, 5000);
    
        return;
      }
    
      try {
        const response = await axios.put(
          `https://backend.kbascontracting.com/ServicesFooter/update/${selectedTermId1}`,
          updateTerm1
        );
    
        if (response.status === 200) {
          console.log("Term updated successfully:", response.data);
          fetchData1();
          setUpdateTerm1({ title: "", description: "" });
          setSelectedTermId1(null);
          setSuccessMessage1("Term updated successfully");
          setUpdateErrorMessage1(""); // Clear any existing error message
          setTimeout(() => {
            setSuccessMessage1("");
          }, 5000);
        }
      } catch (error) {
        console.error("Error updating term:", error);
        setUpdateErrorMessage("Failed to update term. Please try again.");
      }
    };
    


  useEffect(() => {
    fetchData1();
    if (showUpdateForm1) {
      // Assuming the first input field has an id 'update-title'
      document.getElementById('update-title').focus();
    }
  }, [showUpdateForm1]);





  return (
    <div className="content">
  <div >

  
        
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">About</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Title</th>
                        <th>description</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {terms.map((term) => (
                        <tr key={term.id}>
                          <td>{term.title}</td>
                          <td>{term.description}</td>
                          <td>
                            {/* <Button 
                            color="danger"
                            onClick={() => handleDelete(term.id)}>
                              Delete
                            </Button>{" "} */}
                            <Button 
                             color="success"
                            onClick={() => handleUpdate(term)}>
                              Update
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      

      {showUpdateForm  && (
    <div className="content mt-5">
                <Form onSubmit={handleTermUpdate}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>Title:</label>
                  <Input
                    type="text"
                    name="title"
                    id="update-title"
                    value={updateTerm.title}
                    onChange={(e) =>
                      setUpdateTerm({ ...updateTerm, title: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            
              <Col md="9">
                <FormGroup>
                <label for="content" className="textarea-label">description:</label>
               <textarea
                //  style={{ width: '500px' }}
                    type="text"
                    name="description"
                    value={updateTerm.description}
                    onChange={(e) =>
                      setUpdateTerm({ ...updateTerm, description: e.target.value })
                    }
                    style={{ minHeight: '300px', width: '590px'  }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="update ml-auto mr-auto">
                  <Button className="btn-round" color="primary" type="submit">
                    Update Term
                  </Button>{" "}
      <Button
        className="btn-round"
        color="secondary"
        onClick={() => {
          setShowUpdateForm(false);
          setSelectedTermId(null);
          setUpdateTerm({
            title: "",
            description: "",
          });
        }}
      >
        Cancel
      </Button>
      {successMessage && (
        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
          {successMessage}
        </div>
      )}

{updateErrorMessage && (
        <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
          {updateErrorMessage}
        </div>
      )}

                </div>
              </Col>
            </Row>
          </Form>
        </div>
      )}






<div>

  
        
<Row>
  <Col md="12">
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Services</CardTitle>
      </CardHeader>
      <CardBody>
        <Table responsive>
          <thead className="text-primary">
            <tr>
              <th>Title</th>
              <th>description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {terms1.map((term1) => (
              <tr key={term1.id}>
                <td>{term1.title}</td>
                <td>{term1.description}</td>
                <td>

                  <Button 
                   color="success"
                  onClick={() => handleUpdate1(term1)}>
                    Update
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
</Row>
</div>


{showUpdateForm1  && (
<div className="content mt-5">
      <Form onSubmit={handleTermUpdate1}>
  <Row>
    <Col md="6">
      <FormGroup>
        <label>Title:</label>
        <Input
          type="text"
          name="title"
          id="update-title"
          value={updateTerm1.title}
          onChange={(e) =>
            setUpdateTerm1({ ...updateTerm1, title: e.target.value })
          }
        />
      </FormGroup>
    </Col>
  
    <Col md="9">
      <FormGroup>
      <label for="content" className="textarea-label">description:</label>
     <textarea
      //  style={{ width: '500px' }}
          type="text"
          name="description"
          value={updateTerm1.description}
          onChange={(e) =>
            setUpdateTerm1({ ...updateTerm1, description: e.target.value })
          }
          style={{ minHeight: '300px', width: '590px'  }}
        />
      </FormGroup>
    </Col>
  </Row>
  <Row>
    <Col md="12">
      <div className="update ml-auto mr-auto">
        <Button className="btn-round" color="primary" type="submit">
          Update Term
        </Button>{" "}
<Button
className="btn-round"
color="secondary"
onClick={() => {
setShowUpdateForm1(false);
setSelectedTermId1(null);
setUpdateTerm1({
  title: "",
  description: "",
});
}}
>
Cancel
</Button>
{successMessage1 && (
<div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
{successMessage1}
</div>
)}

{updateErrorMessage1 && (
<div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
{updateErrorMessage1}
</div>
)}

      </div>
    </Col>
  </Row>
</Form>
</div>
)}








<div>

  
        
<Row>
  <Col md="12">
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Latest Posts</CardTitle>
      </CardHeader>
      <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Title</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {news.map((term2) => (
                        <tr key={term2.id}>
                          <td>{term2.title}</td>
                          <td>

                            <Button 
                             color="success"
                            onClick={() => handleUpdate4(term2)}>
                              Update
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>


      <CardBody>
        <Table responsive>
          <thead className="text-primary">
            <tr>
              <th>Title</th>
              <th>Image</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {section.map((term2) => (
              <tr key={term2.id}>
                <td>{term2.title}</td>
                <td>
        <img src={`https://backend.kbascontracting.com/${term2.mainImage}`} alt="image" style={{ width: '250px', height: 'auto' }} />
      </td>
                <td>

                  <Button 
                   color="success"
                  onClick={() => handleUpdate2(term2)}>
                    Update
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
</Row>
</div>



{showUpdatenews  && (
    <div className="content mt-5">
                <Form onSubmit={handleTermUpdate4}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>Title:</label>
                  <Input
                    type="text"
                    name="title"
                    id="update-title"
                    value={updatenews.title}
                    onChange={(e) =>
                      setUpdatenews({ ...updatenews, title: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            

            </Row>
            <Row>
              <Col md="12">
                <div className="update ml-auto mr-auto">
                  <Button className="btn-round" color="primary" type="submit">
                    Update News
                  </Button>{" "}
      <Button
        className="btn-round"
        color="secondary"
        onClick={() => {
          setShowUpdatenews(false);
          setSelectednewsId(null);
          setUpdatenews({
            title: "",
          });
        }}
      >
        Cancel
      </Button>
      {successMessagenews && (
        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
          {successMessagenews}
        </div>
      )}

{updateErrorMessagenews && (
        <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
          {updateErrorMessagenews}
        </div>
      )}

                </div>
              </Col>
            </Row>
          </Form>
        </div>
      )}





{showUpdatesection  && (
<div className="content mt-5">
      <Form onSubmit={handleTermUpdate2}>
  <Row>
    <Col md="6">
      <FormGroup>
        <label>Title:</label>
        <Input
          type="text"
          name="title"
          id="update-title"
          value={updatesection.title}
          onChange={(e) =>
            setUpdatesection({ ...updatesection, title: e.target.value })
          }
        />
      </FormGroup>
    </Col>
  
    <Col md="12">
          <FormGroup>
            {updatesection.mainImage && (
              <div>
                <label>Current Image:</label>
                <div>
                  <img src={`https://backend.kbascontracting.com/${updatesection.mainImage}`}alt="Current" style={{ width: '250px', height: 'auto' }} />
                </div>
              </div>
            )}
            <label className="mt-3">Upload New Image:</label>
            <Input
              type="file"
              name="image"
              onChange={handleFileChange}
            />
          </FormGroup>
        </Col>
  </Row>
  <Row>
    <Col md="12">
      <div className="update ml-auto mr-auto">
        <Button className="btn-round" color="primary" type="submit">
          Update Term
        </Button>{" "}
<Button
className="btn-round"
color="secondary"
onClick={() => {
  setShowUpdatesection(false);
  setSelectedsection(null);
setUpdatesection({
  title: "",
  description: "",
});
}}
>
Cancel
</Button>
{errorsection && (
  <div style={{ color: 'red', marginTop: '10px' }}>
{errorsection}
</div>
)}

{updateErrorMessagesection && (
  <div style={{ color: 'red', marginTop: '10px' }}>
{updateErrorMessagesection}
</div>
)}

      </div>
    </Col>
  </Row>
</Form>
</div>
)}






{/* <div >

  
        
<Row>
  <Col md="12">
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Subscribe </CardTitle>
      </CardHeader>
      <CardBody>
        <Table responsive>
          <thead className="text-primary">
            <tr>
              <th>Title</th>
              <th>description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Subscribe.map((term7) => (
              <tr key={term7.id}>
                <td>{term7.title}</td>
                <td>{term7.description}</td>
                <td>

                  <Button 
                   color="success"
                  onClick={() => handleUpdate7(term7)}>
                    Update
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
</Row>
</div> */}


{/* {showUpdateSubscribe  && (
<div className="content mt-5">
      <Form onSubmit={handleTermUpdate7}>
  <Row>
    <Col md="6">
      <FormGroup>
        <label>Title:</label>
        <Input
          type="text"
          name="title"
          id="update-title"
          value={updateSubscribe.title}
          onChange={(e) =>
            setUpdateSubscribe({ ...updateSubscribe, title: e.target.value })
          }
        />
      </FormGroup>
    </Col>
  
    <Col md="9">
      <FormGroup>
      <label for="content" className="textarea-label">description:</label>
     <textarea
   
          type="text"
          name="description"
          value={updateSubscribe.description}
          onChange={(e) =>
            setUpdateSubscribe({ ...updateSubscribe, description: e.target.value })
          }
          style={{ minHeight: '300px', width: '590px'  }}
        />
      </FormGroup>
    </Col>
  </Row>
  <Row>
    <Col md="12">
      <div className="update ml-auto mr-auto">
        <Button className="btn-round" color="primary" type="submit">
          Update Subscribe
        </Button>{" "}
<Button
className="btn-round"
color="secondary"
onClick={() => {
  setShowUpdateSubscribe(false);
  setSelectedSubscribeId(null);
  setUpdateSubscribe({
  title: "",
  description: "",
});
}}
>
Cancel
</Button>


{updateErrorMessageSubscribe && (
<div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
{updateErrorMessageSubscribe}
</div>
)}

      </div>
    </Col>
  </Row>
</Form>
</div>
)} */}



    </div>
  );
}

export default Footer;
