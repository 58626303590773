import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button ,Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

function Icons() {
  const [icons, setIcons] = useState([]);
  const [editingIcon, setEditingIcon] = useState(null);
  const [iconCount, setIconCount] = useState(0);
  const [newImage, setNewImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [addError, setAddError] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [ImageError, setImageError] = useState("");
  const [ImageErrorUpdate, setImageErrorUpdate] = useState("");
  const [formInitialDisplay, setFormInitialDisplay] = useState(false);
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteIconId, setDeleteIconId] = useState(null);




  

  const fetchIcons = async () => {
    try {
      const response = await axios.get("https://backend.kbascontracting.com/social/list");
      if (response.data.success) {
        setIcons(response.data.data);
        setIconCount(response.data.data.length);
      } else {
        console.error("API request was not successful:", response.data.errors);
      }
    } catch (error) {
      console.error("Error fetching icons:", error);
    }
  };

  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };
  const addIcon = async () => {
    const nameInput = document.getElementById("newIconName");
    const urlInput = document.getElementById("newIconurl");
    const imageInput = document.getElementById("newIconimage");
  
    const name = nameInput.value;
    const url = urlInput.value;
    const imageFile = imageInput.files[0];
  
    if (!name || !url || !imageFile) {
      setAddError("All fields are required.");
      setTimeout(() => { setAddError(""); }, 5000);
      return;
    }
  
    if (!isValidImageExtension(imageFile.name)) {
      setImageError("Invalid file extension. Please upload .WEBP, .GIF, .PNG, .JPG, or .JPEG");
      setTimeout(() => { setImageError(""); }, 5000);
      return;
    }
  
    const formData = new FormData();
    formData.append("name", name);
    formData.append("url", url);
    formData.append("image", imageFile);
  
    try {
      const response = await axios.post("https://backend.kbascontracting.com/social/add", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      if (response.data.success) {
        fetchIcons();
        setAddSuccessMessage("Social Media added successfully");
        setTimeout(() => { setAddSuccessMessage(""); }, 5000);
  
        nameInput.value = "";
        urlInput.value = "";
        imageInput.value = "";
  
      } else {
        setAddError("Failed to add icon: " + response.data.errors);
      }
    } catch (error) {
      console.error("Error adding icon:", error);
      setAddError("Error occurred whileadding the icon.");
  }
  };
  
  const handleUpdate = (icon) => {
    setEditingIcon(icon);
    setShowUpdateForm(true); // Show the update form
  };
  const closeUpdateForm = () => {
    setEditingIcon(null);
    setShowUpdateForm(false);
  };
  

  const updateIcon = async (id) => {
    // Validation checks
    if (!editingIcon.name || !editingIcon.url) {
      setUpdateError("Name and URL are required.");
      setTimeout(() => {
        setUpdateError("");
      }, 5000);
      return;
    }
  
    // Creating a FormData object to handle the data being sent in the PUT request
    const formData = new FormData();
    formData.append("name", editingIcon.name);
    formData.append("url", editingIcon.url);
  
    // Adding the new image to the formData only if it exists and is valid
    if (newImage) {
      if (!isValidImageExtension(newImage.name)) {
        setImageErrorUpdate("Invalid file extension. Allowed: .WEBP, .GIF, .PNG, .JPG, .JPEG");
        setTimeout(() => {
          setImageErrorUpdate("");
        }, 5000);
        return;
      }
      formData.append("image", newImage);
    }
  
    // Making an asynchronous PUT request to update the icon
    try {
      const response = await axios.put(`https://backend.kbascontracting.com/social/update/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      // Handling the response after the icon is successfully updated
      if (response.data.success) {
        fetchIcons(); // Refresh the list of icons
        setUpdateSuccessMessage("Social Media updated successfully");
        setTimeout(() => {
          setUpdateSuccessMessage("");
        }, 5000);
  
        // Reset the update form fields but keep the update section visible
        setEditingIcon({ ...editingIcon, name: "", url: "", image: "" });
        setNewImage(null);
      } else {
        setUpdateError("Failed to update icon: " + response.data.errors);
      }
    } catch (error) {
      setUpdateError("Error occurred while updating the icon.");
    }
  };
  
  

  
const deleteIcon = (id) => {
  setDeleteIconId(id);
  setShowDeletePopup(true);
};



const performDelete = async () => {
  try {
    const response = await axios.delete(`https://backend.kbascontracting.com/social/delete/${deleteIconId}`);
    if (response.data.success) {
      setIcons(icons.filter((icon) => icon.id !== deleteIconId));
      setShowDeletePopup(false);
    } else {
      console.error("Failed to delete icon");
    }
  } catch (error) {
    console.error("Error deleting icon:", error);
  }
};


  useEffect(() => {
    fetchIcons();
  }, []);

  useEffect(() => {
    if (editingIcon && !formInitialDisplay) {
      setFormInitialDisplay(true); // Indicate that the form is being displayed initially
      const nameInputField = document.getElementById('edit-icon-name');
      if (nameInputField) {
        nameInputField.focus();
      }
    } else if (!editingIcon && formInitialDisplay) {
      setFormInitialDisplay(false); // Reset when the form is closed
    }
  }, [editingIcon]);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Social Media</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="5">
                      <FormGroup>
                        <label>Name:</label>
                        <Input
                          type="text"
                          name="name"
                          id="newIconName"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="5">
                      <FormGroup>
                        <label>url:</label>
                        <Input
                          type="text"
                          name="url"
                          id="newIconurl"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="8">
                      <FormGroup>
                        <label>Icons:</label>
                        <Input
                          type="file"
                          name="image"
                          id="newIconimage"
                          onChange={(e) => {
                            setNewImage(e.target.files[0]);
                            
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <Button
                        className="btn-round"
                        color="primary"
                        onClick={addIcon}
                      >
                        Add Social Media
                      </Button>
                      {addSuccessMessage && (
                        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
                          {addSuccessMessage}
                        </div>
                      )}
                                       {addError && (
  <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
    {addError}
  </div>
)}
{ImageError && (
  <div style={{ color: 'red' }}>
    {ImageError}
  </div>
)}


                    </Col>
                  </Row>
                </Form>
              

                <CardFooter>
                  <div>Total Social Media: {iconCount}</div>
                </CardFooter>
                
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Name</th>
                      <th>url</th>
                      <th>Icons</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {icons.map((icon) => (
                      <tr key={icon.id}>
                        <td>{icon.name}</td>
                        <td>{icon.url}</td>
                        <td>
                          {icon.image && (
                            <img
                              src={`https://backend.kbascontracting.com/${icon.image}`}
                              style={{ maxWidth: "100px" }}
                            />
                          )}
                        </td>
                        <td>
                          <Button
                            color="success"
                            onClick={() => handleUpdate(icon)}
                          >
                            Update
                          </Button>{" "}
                          <Button
                            color="danger"
                            onClick={() => deleteIcon(icon.id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
              {showUpdateForm && editingIcon && (
                <CardFooter>
                  <Form>
                    <Row>
                      <Col md="5">
                        <FormGroup>
                          <label>Update Name:</label>
                          <Input
                            type="text"
                            name="name"
                            
                            value={editingIcon.name}
                            onChange={(e) =>
                              setEditingIcon({
                                ...editingIcon,
                                name: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="5">
                        <FormGroup>
                          <label>Update url:</label>
                          <Input
                            type="text"
                            name="url"
                            id="edit-icon-name"
                            value={editingIcon.url}
                            onChange={(e) =>
                              setEditingIcon({
                                ...editingIcon,
                                url: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="8">
          <FormGroup>
            <label>Current Icon:</label>
            {editingIcon.image && (
              <div>
                <img
                  src={`https://digitalback.we-demo.xyz/uploads/${editingIcon.image}`}
                  alt="Icon"
                  style={{ maxWidth: '200px', marginBottom: '10px' }}
                />
              </div>
            )}
            <label>Update Icon (optional):</label>
            <Input
              type="file"
              name="image"
              onChange={(e) => setNewImage(e.target.files[0])}
            />
          </FormGroup>
        </Col>
                      <Col md="12">
                        <Button
                          className="btn-round"
                          color="info"
                          onClick={() => updateIcon(editingIcon.id)}
                        >
                          Update Social Media
                        </Button>{" "}
                        <Button
      className="btn-round"
      color="secondary"
      onClick={closeUpdateForm}
    >
      Cancel
    </Button>
                         {updateSuccessMessage && (
                            <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
                              {updateSuccessMessage}
                            </div>
                          )}
{updateError && (
  <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
    {updateError}
  </div>
)}
{ImageErrorUpdate && (
  <div style={{ color: 'red' }}>
    {ImageErrorUpdate}
  </div>
)}


                      </Col>
                    </Row>
                  </Form>
                </CardFooter>
              )}
            </Card>
          </Col>
        </Row>
        <Modal isOpen={showDeletePopup} toggle={() => setShowDeletePopup(false)}>
  <ModalHeader toggle={() => setShowDeletePopup(false)}>Confirm Delete</ModalHeader>
  <ModalBody>
    Are you sure you want to delete this icon?
  </ModalBody>
  <ModalFooter>
    <Button color="danger" onClick={performDelete}>Delete</Button>
    <Button color="secondary" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
  </ModalFooter>
</Modal>

      </div>
    </>
  );
}

export default Icons;
