import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, CardBody, CardFooter, CardTitle, Row, Col } from "reactstrap";

function Dashboard() {

  const [SocialMediacount, setSocialMedia] = useState([]);
  const [HomePage, setHomePage] = useState([]);

  const [termCount, setTermCount] = useState(0);
  const [InfoBarCount, setInfoBarCount] = useState([]);
  const [notification, setnotification] = useState([]);
  
  const userRole = 'admin'; 

  




  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://backend.kbascontracting.com/InfoBar/list"
        );
        // setProduct(response.data);
        console.log(`rrr${response}`)
        setInfoBarCount(response.data.data);
      } catch (error) {
        console.log(`Error getting news from frontend: ${error}`);
      }
    };

    fetchData();
  }, []);

 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://backend.kbascontracting.com/AboutFooter/list");
        if (response.data && Array.isArray(response.data.data)) {
          setTermCount(response.data.data.length); // Correctly set the count of terms
        } else {
          console.error("Unexpected response format:", response.data);
          // Handle this situation appropriately
        }
      } catch (error) {
        console.error(`Error getting terms from frontend: ${error}`);
      }
    };
  
    fetchData();
  }, []);
  
  
  

 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://backend.kbascontracting.com/navbar/list"
        );
        setnotification(response.data.data);
      } catch (error) {
        console.log(`Error getting Blog from frontend: ${error}`);
      }
    };

    fetchData();
  }, []);

  

  return (
    <>
      <div className="content">
        <Row>


          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chat-33 text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Social Media</p>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>


          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-single-copy-04 text-warning" />
                    </div>
                  </Col>
                <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Info Bar</p>
                      <p />
                    </div>
                  </Col>

                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>


          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-bullet-list-67 text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Navbar</p>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>


          

          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-tv-2 text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Home Page</p>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>



          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-simple-add text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Add Admin</p>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>




          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-paper text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Footer</p>
                      <p />
                    </div>
                  </Col>


                



                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>
         




        </Row>
    
      </div>
    </>
  );
}

export default Dashboard;
