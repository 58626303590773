import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../assets/css/style.css";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

function PrivacyPolicies() {
  const [policies, setPolicies] = useState([]);

  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedPolicyId, setSelectedPolicyId] = useState(null);
  const [updatePolicy, setUpdatePolicy] = useState({
    title: "",
    content: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [policies1Count, setpolicies1Count] = useState(0);

 

  const [infoBarevents, setInfoBarevents] = useState([]);
  const [showUpdateForminfoBar, setShowUpdateForminfoBar] = useState(false);
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    title: "",
    date: "",
    content: "",
  });
  const [eventImage, setEventImage] = useState(null);
  // const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [updateEvent, setUpdateEvent] = useState({
    email: "",
    logo: "",
    phone: "",
    icon: "", 
  })
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  const [InfoBarCount, setInfoBarCount] = useState(0); 
  const [addError, setAddError] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [AddRequired, setAddRequired] = useState("");
  const [UpdateRequired, setUpdateRequired] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  
  const [additionalImages, setAdditionalImages] = useState([]);
  const [ImageError, setImageError] = useState("");
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [newBackgroundImage, setNewBackgroundImage] = useState(null);
  const [showBackgroundImageUpdateForm, setShowBackgroundImageUpdateForm] = useState(false);
  const [services, setServices] = useState([]);
  const [showUpdateFormservices, setShowUpdateFormservices] = useState(false);

  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [updateService, setUpdateService] = useState({
    title: "",
    paragraph: "",
  });

  const [servicesData, setServicesData] = useState([]);
  const [showUpdateFormservicesData, setShowUpdateFormservicesData] = useState(false);
  const [deleteIconId, setDeleteIconId] = useState(null);
  const [updatedServiceData, setUpdatedServiceData] = useState({
    updatedTitle: '',
    updatedParagraph: '',
    updatedIconImage: null,
    updatedMainImage: null,
  });
  const [projectItems, setProjectItems] = useState([]);
  const [showUpdateFormproject, setShowUpdateFormproject] = useState(false);
 
const [selectedImageId, setSelectedImageId] = useState(null);

  const [updatedProjectItem, setUpdatedProjectItem] = useState({
    title: '',
    paragraph: '',
  });
  const [selectedProjectItemId, setSelectedProjectItemId] = useState(null); 
  const [backgroundImagesData, setBackgroundImagesData] = useState([]);
  const [showUpdateFormselectedProject, setShowUpdateFormselectedProject] = useState(false);
  const [updatedBackgroundImageData, setUpdatedBackgroundImageData] = useState({
    path: null, // Use "image" for both main image and icon image
  });
  const [selectedBackgroundImageId, setSelectedBackgroundImageId] = useState(null);
  const [newsData, setNewsData] = useState([]);
  const [showUpdateFormnews, setShowUpdateFormnews] = useState(false);
const [updatedNewsItem, setUpdatedNewsItem] = useState({
  title: '',
  paragraph: '',
});
const [selectedNewsItemId, setSelectedNewsItemId] = useState(null);
const [selectedNewsSectionId, setSelectedNewsSectionId] = useState(null);
const [newsSectionData, setNewsSectionData] = useState([]);
const [showUpdateFormnewsSection, setShowUpdateFormnewsSection] = useState(false);
const [updatedNewsSectionData, setUpdatedNewsSectionData] = useState({
  mainImage: null,
  title: '',
  // subtitle: '',
  paragraph: '',
});

const [backgroundImagesData1, setBackgroundImagesData1] = useState([]);
const [showUpdateFormImagesData, setShowUpdateFormImagesData] = useState(false);
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState(null);
  const [updatedBackgroundImageData1, setUpdatedBackgroundImageData1] = useState({
    path: null,
  });




  const fetchData7 = async () => {
    try {
      const response = await axios.get('https://backend.kbascontracting.com/Partners/list'); // Adjust the API endpoint as needed
      if (response.data.success) {
        setBackgroundImagesData1(response.data.data);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setUpdateErrorMessage('Error fetching data. Please try again later.');
    }
  };


  const handleAddImage = async (event) => {
    event.preventDefault();
  
    const fileInput = document.getElementById('newImagePath');
  
    if (!fileInput || !fileInput.files[0]) {
      setImageError('Please select an image to upload.');
      setTimeout(() => setImageError(''), 5000);
      return;
    }
  
    // Validate the image extension if needed
    if (!isValidImageExtension(fileInput.files[0].name)) {
      setImageError('Invalid image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg.');
      setTimeout(() => setImageError(''), 5000);
      return;
    }
  
    const formData = new FormData();
    formData.append('image', fileInput.files[0]);
  
    try {
      const response = await axios.post('https://backend.kbascontracting.com/Partners/add', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
  
      if (response.status === 200 || response.status === 201) {
        fetchData7();
        setAddSuccessMessage('Image added successfully');
        setTimeout(() => setAddSuccessMessage(''), 5000);
        // Optionally, update the UI or state to include the new image
      } else {
        setAddError('Failed to add the image');
      }
    } catch (error) {
      console.error('Error adding the image:', error);
      setAddError('Failed to add the image. Please try again.');
      setTimeout(() => setAddError(''), 5000);
    }
  };



  const promptDeleteImage = (imageId) => {
    setSelectedImageId(imageId);
    setShowDeletePopup(true);
  };
  
  const handleDeleteImage = async () => {
    if (selectedImageId !== null) {
      try {
        const response = await axios.delete(`https://backend.kbascontracting.com/Partners/delete/${selectedImageId}`);
        if (response.data.success) {
          fetchData7();
          setShowDeletePopup(false);
          setSelectedImageId(null);
          // Optionally, show a success message
        } else {
          // Handle unsuccessful deletion
        }
      } catch (error) {
        console.error('Error deleting the image:', error);
        // Optionally, show an error message
      }
    }
  };
  
  




  // Function to handle updating background image
  const handleUpdateBackgroundImage1 = (backgroundImage) => {
    setSelectedBackgroundImage(backgroundImage); // Set the selected background image
    setShowUpdateFormImagesData(true);
  };

  // Function to handle updating the background image
  const handleBackgroundImageUpdate1 = async (e) => {
    e.preventDefault();

    if (selectedBackgroundImage === null) {
      console.error('selectedBackgroundImage is not defined');
      return;
    }

    // Prepare the data to send for updating
    const formData = new FormData();

    // Check if updatedBackgroundImageData.path is a File and validate its extension
    if (updatedBackgroundImageData.path instanceof File) {
        if (!isValidImageExtension(updatedBackgroundImageData.path.name)) {
            setUpdateErrorMessage('Invalid background image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg');
            setTimeout(() => setUpdateErrorMessage(''), 5000);
            return;
        }
        formData.append('image', updatedBackgroundImageData.path);
    } else {
        console.error('No new image file selected');
        setUpdateErrorMessage('No new image file selected');
        setTimeout(() => setUpdateErrorMessage(''), 5000);
        return;
    }

    try {
      const response = await axios.put(
        `https://backend.kbascontracting.com/Partners/update/${selectedBackgroundImage.id}`,
        formData
      );

      if (response.data.success) {
        fetchData7(); // Fetch data again after update
        setSelectedBackgroundImage(null);
        setUpdatedBackgroundImageData({
          path: null, // Clear "path" after update
        });
        setSuccessMessage('Background image updated successfully');
        setUpdateErrorMessage('');
        setShowUpdateFormImagesData(false);
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);
      }
    } catch (error) {
      console.error('Error updating background image:', error);
      setUpdateErrorMessage('Failed to update background image. Please try again.');
      setTimeout(() => {
        setUpdateErrorMessage("");
      }, 5000);
    }
  };

  // Fetch data when the component mounts
  useEffect(() => {
    fetchData7();
  }, [showUpdateFormImagesData]);


  useEffect(() => {
    if (showUpdateFormImagesData) {
      // Assuming you want to focus on the 'name' input field when editing
      const nameInputField = document.getElementById('edit-navbar-name');
      if (nameInputField) {
        nameInputField.focus();
      }
    }
  }, [showUpdateFormImagesData]);







const handleMainImageChange1 = (e) => {
  if (e.target.files && e.target.files[0]) {
    setUpdatedNewsSectionData({ 
      ...updatedNewsSectionData, 
      mainImage: e.target.files[0] 
    });
  }
};


const fetchData6 = async () => {
  try {
    const response = await axios.get('https://backend.kbascontracting.com/NewsSection/list'); // Adjust the API endpoint as needed
    if (response.data.success) {
      setNewsSectionData(response.data.data);
    } else {
      console.error('Unexpected response format:', response.data);
    }
  } catch (error) {
    console.error(`Error getting data: ${error}`);
    setUpdateErrorMessage('Error fetching data. Please try again later.');
  }
};



const handleSubmit = async (event) => {
  event.preventDefault();

  const fileInput = document.getElementById('newsMainImage');

  if (!fileInput || !fileInput.files[0]) {
    setAddError('Please select an image to upload.');
    setTimeout(() => setAddError(''), 5000);
    return;
  }

  // Validate the image extension (implement isValidImageExtension as needed)
  if (!isValidImageExtension(fileInput.files[0].name)) {
    setAddError('Invalid image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg.');
    setTimeout(() => setAddError(''), 5000);
    return;
  }

  const formData = new FormData();
  formData.append('title', document.getElementById('newsTitle').value);
  formData.append('paragraph', document.getElementById('newsParagraph').value);
  formData.append('mainImage', fileInput.files[0]);

  try {
    const response = await axios.post('https://backend.kbascontracting.com/NewsSection/add', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response.status === 200 || response.status === 201) {
      fetchData6(); 
      const newsData = response.data.data;

      // Assuming the newsData contains the necessary information
      if (newsData) {
        // Update your state or UI here
        setAddSuccessMessage('News section added successfully');
        setTimeout(() => setAddSuccessMessage(''), 5000);
      } else {
        console.error('Newly added news section data is incomplete:', newsData);
        setAddError('Failed to display newly added news section. Please refresh.');
      }
    }
  } catch (error) {
    console.error('Error adding news section:', error);
    setAddError('Failed to add news section. Please try again.');
    setTimeout(() => setAddError(''), 5000);
  }
};



const promptDeleteNewsSection = (newsSectionId) => {
  setSelectedNewsSectionId(newsSectionId);
  setShowDeletePopup(true);
};




const handleDeleteNewsSection = async () => {
  if (selectedNewsSectionId !== null) {
    try {
      const response = await axios.delete(`https://backend.kbascontracting.com/NewsSection/delete/${selectedNewsSectionId}`);
      if (response.data.success) {
        fetchData6(); 
        setShowDeletePopup(false);
        setSelectedNewsSectionId(null);
        // Optionally, show a success message
      } else {
        // Handle the case where the deletion is unsuccessful
      }
    } catch (error) {
      console.error('Error deleting news section:', error);
      // Optionally, show an error message
    }
  }
};





const handleUpdateNewsSection = (newsSection) => {
  setSelectedNewsSectionId(newsSection.id);
  setUpdatedNewsSectionData({
    mainImage: newsSection.mainImage,
    title: newsSection.title,
    // subtitle: newsSection.subtitle,
    paragraph: newsSection.paragraph,
  });
  setShowUpdateFormnewsSection(true);
};


const handleNewsSectionUpdate = async (e) => {
  e.preventDefault();

  if (selectedNewsSectionId === null) {
    console.error('selectedNewsSectionId is not defined');
    return;
  }

  // Prepare the data to send for updating
  const formData = new FormData();
  formData.append('title', updatedNewsSectionData.title);
  formData.append('paragraph', updatedNewsSectionData.paragraph);

  // Append file only if it's available and a new file has been selected
  if (updatedNewsSectionData.mainImage instanceof File) {
    if (!isValidImageExtension(updatedNewsSectionData.mainImage.name)) {
        setUpdateErrorMessage('Invalid background image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg');
        setTimeout(() => setUpdateErrorMessage(''), 5000);
        return;
    }
    formData.append('mainImage', updatedNewsSectionData.mainImage);
}
  try {
    // Send a PUT request to update the news section
    const response = await axios.put(
      `https://backend.kbascontracting.com/NewsSection/update/${selectedNewsSectionId}`,
      formData
    );

    if (response.data.success) {
      fetchData6(); // Fetch data again after update
      setSelectedNewsSectionId(null);
      setUpdatedNewsSectionData({
        mainImage: null, // Clear the mainImage after update
        title: '', // Clear the title after update
        // subtitle: '', // Clear the subtitle after update
        paragraph: '', // Clear the paragraph after update
      });
      setSuccessMessage('News section updated successfully');
      setUpdateErrorMessage('');
      setShowUpdateFormnewsSection(false);
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    }
  } catch (error) {
    console.error('Error updating news section:', error);
    setUpdateErrorMessage('Failed to update news section. Please try again.');
  }
};
useEffect(() => {
  fetchData6();
}, [showUpdateFormnewsSection]);


useEffect(() => {
  if (showUpdateFormnewsSection) {
    // Assuming you want to focus on the 'name' input field when editing
    const nameInputField = document.getElementById('edit-navbar-name');
    if (nameInputField) {
      nameInputField.focus();
    }
  }
}, [showUpdateFormnewsSection]);







const fetchData5 = async () => {
  try {
    const response = await axios.get('https://backend.kbascontracting.com/News/list');
    if (response.data.success) {
      setNewsData(response.data.data);
    } else {
      console.error('Unexpected response format:', response.data);
    }
  } catch (error) {
    console.error(`Error getting data: ${error}`);
    setUpdateErrorMessage('Error fetching data. Please try again later.');
  }
};





const handleUpdateNewsItem = (newsItem) => {
  setSelectedNewsItemId(newsItem.id);
  setUpdatedNewsItem({
    title: newsItem.title,
    paragraph: newsItem.paragraph,
  });
  setShowUpdateFormnews(true);
};








const handleNewsItemUpdate = async (e) => {
  e.preventDefault();

  if (selectedNewsItemId === null) {
    console.error('Selected News Item ID is not defined');
    setUpdateErrorMessage('No News Item selected for update.');
    return;
  }

  if (!updatedNewsItem.title.trim() || !updatedNewsItem.paragraph.trim()) {
    console.error('Title or paragraph cannot be empty');
    setUpdateErrorMessage('Title and paragraph are required.');
    return;
  }

  try {
    const response = await axios.put(
      `https://backend.kbascontracting.com/News/update/${selectedNewsItemId}`,
      {
        title: updatedNewsItem.title,
        paragraph: updatedNewsItem.paragraph,
      }
    );

    if (response.data.success) {
      fetchData5(); // Fetch data again after update
      setSelectedNewsItemId(null);
      setUpdatedNewsItem({
        title: '', // Clear the title after update
        paragraph: '', // Clear the paragraph after update
      });
      setSuccessMessage('News item updated successfully');
      setUpdateErrorMessage('');
      setShowUpdateFormnews(false);
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    } else {
      console.error('Update failed:', response.data.errors);
      setUpdateErrorMessage('Failed to update news item. Please try again.');
    }
  } catch (error) {
    console.error('Error updating news item:', error);
    setUpdateErrorMessage('Failed to update news item. Please try again.');
  }
};


useEffect(() => {
  fetchData5();
}, [showUpdateFormnews]);


useEffect(() => {
  if (showUpdateFormnews) {
    // Assuming you want to focus on the 'name' input field when editing
    const nameInputField = document.getElementById('edit-navbar-name');
    if (nameInputField) {
      nameInputField.focus();
    }
  }
}, [showUpdateFormnews]);








  
  
  const fetchData4 = async () => {
    try {
      const response = await axios.get('https://backend.kbascontracting.com/ProjectsImages/list');
      if (response.data.success) {
        setBackgroundImagesData(response.data.data);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setUpdateErrorMessage('Error fetching data. Please try again later.');
    }
  };
  
  const handleUpdateBackgroundImage = (backgroundImage) => {
    setSelectedBackgroundImageId(backgroundImage.id); // Set the selected background image ID
    setUpdatedBackgroundImageData({
      path: backgroundImage.path, // Use "path" for both main image and icon image
    });
    setShowUpdateFormselectedProject(true);
  };
  
  
const handleBackgroundImageUpdate2 = async (e) => {
  e.preventDefault();

  if (selectedBackgroundImageId === null) {
    console.error('selectedBackgroundImageId is not defined');
    return;
  }

  // Check if updatedBackgroundImageData.path is a File object and validate its extension
  if (updatedBackgroundImageData.path && updatedBackgroundImageData.path instanceof File) {
    if (!isValidImageExtension(updatedBackgroundImageData.path.name)) {
      setUpdateErrorMessage("Invalid image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
      setTimeout(() => setUpdateErrorMessage(""), 5000);
      return;
    }
  } else {
    console.error('No image file selected for update');
    setUpdateErrorMessage("No image file selected for update");
    setTimeout(() => setUpdateErrorMessage(""), 5000);
    return;
  }

  const formData = new FormData();
  formData.append('image', updatedBackgroundImageData.path); // Append the image file


  try {
    const response = await axios.put(
      `https://backend.kbascontracting.com/ProjectsImages/update/${selectedBackgroundImageId}`,
      formData
    );

    if (response.data.success) {
      fetchData4(); // Fetch data again after update
      setSelectedBackgroundImageId(null);
      setUpdatedBackgroundImageData({
        path: null, // Clear "path" after update
      });
      setSuccessMessage('Background image updated successfully');
      setUpdateErrorMessage('');
      setShowUpdateFormselectedProject(false);
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    }
  } catch (error) {
    console.error('Error updating background image:', error);
    setUpdateErrorMessage('Failed to update background image. Please try again.');
    setTimeout(() => {
      setUpdateErrorMessage("");
    }, 5000);
  }
};



  useEffect(() => {
    fetchData4();
}, [showUpdateFormselectedProject]);


useEffect(() => {
  if (showUpdateFormselectedProject) {
    // Assuming you want to focus on the 'name' input field when editing
    const nameInputField = document.getElementById('edit-navbar-name');
    if (nameInputField) {
      nameInputField.focus();
    }
  }
}, [showUpdateFormselectedProject]);




  const fetchProjectItems = async () => {
    try {
      const response = await axios.get('https://backend.kbascontracting.com/Projects/list');
      if (response.data.success) {
        setProjectItems(response.data.data);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setUpdateErrorMessage('Error fetching data. Please try again later.');
    }
  };


  const handleAddProjectImage = async () => {
    const fileInput = document.getElementById('newProjectImage');
    
    if (!fileInput || !fileInput.files[0]) {
      setAddError('Please select an image to upload.');
      setTimeout(() => setAddError(''), 5000);
      return;
    }
  
    // Validate the image extension
    if (!isValidImageExtension(fileInput.files[0].name)) {
      setAddError('Invalid image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg.');
      setTimeout(() => setAddError(''), 5000);
      return;
    }
  
    const formData = new FormData();
    formData.append('image', fileInput.files[0]);
  
    try {
      const response = await axios.post('https://backend.kbascontracting.com/ProjectsImages/add', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
  
      if (response.status === 200 || response.status === 201) {
        const newImageData = response.data.data;
  
        if (newImageData && newImageData.path) {
          setBackgroundImagesData(prevData => [newImageData, ...prevData]);
          setAddSuccessMessage('Project image added successfully');
          setTimeout(() => setAddSuccessMessage(''), 5000);
        } else {
          console.error('Newly added image data is incomplete:', newImageData);
          setAddError('Failed to display newly added image. Please refresh.');
        }
      }
    } catch (error) {
      console.error('Error adding project image:', error);
      setAddError('Failed to add project image. Please try again.');
      setTimeout(() => setAddError(''), 5000);
    }
  };
  
  
  const confirmDelete = (id) => {
    setSelectedImageId(id);
    setShowDeletePopup(true);
  };
  
  
  
  
  const handleDeleteProjectImage = async () => {
    if (!selectedImageId) return;
  
    try {
      const response = await axios.delete(`https://backend.kbascontracting.com/projectsImages/delete/${selectedImageId}`);
      if (response.status === 200) {
        setBackgroundImagesData(prevData => prevData.filter(item => item.id !== selectedImageId));
        setSuccessMessage('Project image deleted successfully');
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);
      }
    } catch (error) {
      console.error('Error deleting project image:', error);
      setUpdateErrorMessage('Failed to delete project image. Please try again.');
    }
  
    setShowDeletePopup(false);
    setSelectedImageId(null);
  };
  
  
  

  const handleUpdateProjectItem = (item) => {
    setUpdatedProjectItem({
      title: item.title,
      paragraph: item.paragraph,
    });
    setSelectedProjectItemId(item.id);
    setShowUpdateFormproject(true);
  };

  const handleProjectItemUpdate = async (e) => {
    e.preventDefault();

    if (!updatedProjectItem.title.trim() || !updatedProjectItem.paragraph.trim()) {
      setUpdateErrorMessage('Title and Paragraph are required.');
      setTimeout(() => {
        setUpdateErrorMessage('');
      }, 5000);
      return;
    }

    try {
      const response = await axios.put(
        `https://backend.kbascontracting.com/Projects/update/${selectedProjectItemId}`,
        updatedProjectItem
      );

      if (response.data.success) {
        fetchProjectItems();
        setUpdatedProjectItem({
          title: '',
          paragraph: '',
        });
        setSuccessMessage('Project item updated successfully');
        setUpdateErrorMessage('');
        setShowUpdateFormproject(false);
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);
      }
    } catch (error) {
      console.error('Error updating project item:', error);
      setUpdateErrorMessage('Failed to update project item. Please try again.');
    }
  };

  useEffect(() => {
    fetchProjectItems();
  }, [showUpdateFormproject]);


  useEffect(() => {
    if (showUpdateFormproject) {
      // Assuming you want to focus on the 'name' input field when editing
      const nameInputField = document.getElementById('edit-navbar-name');
      if (nameInputField) {
        nameInputField.focus();
      }
    }
  }, [showUpdateFormproject]);




  const fetchData3 = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://backend.kbascontracting.com/ServicesSection/list');
      if (response.data.success) {
        setServicesData(response.data.data);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setUpdateErrorMessage('Error fetching data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };


  const handleAddService = async () => {
    // Validate inputs
    const title = document.getElementById('newServiceTitle').value;
    const paragraph = document.getElementById('newServiceParagraph').value;
    const mainImageInput = document.getElementById('newServiceMainImage');
    const iconImageInput = document.getElementById('newServiceIconImage');
  
    if (!title.trim()) {
      setAddError('Title is required.');
      setTimeout(() => setAddError(''), 5000);
      return;
    }
  
    if (!paragraph.trim()) {
      setAddError('Paragraph is required.');
      setTimeout(() => setAddError(''), 5000);
      return;
    }
  
    if (!mainImageInput.files[0]) {
      setAddError('Main image is required.');
      setTimeout(() => setAddError(''), 5000);
      return;
    } else if (!isValidImageExtension(mainImageInput.files[0].name)) {
      setAddError('Invalid main image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg.');
      setTimeout(() => setAddError(''), 5000);
      return;
    }
  
    if (!iconImageInput.files[0]) {
      setAddError('Icon image is required.');
      setTimeout(() => setAddError(''), 5000);
      return;
    } else if (!isValidImageExtension(iconImageInput.files[0].name)) {
      setAddError('Invalid icon image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg.');
      setTimeout(() => setAddError(''), 5000);
      return;
    }
  
    // If all validations pass
    const formData = new FormData();
    formData.append('title', title);
    formData.append('paragraph', paragraph);
    formData.append('mainImage', mainImageInput.files[0]);
    formData.append('iconImage', iconImageInput.files[0]);
  
    try {
      const response = await axios.post('https://backend.kbascontracting.com/ServicesSection/add', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.status === 200 || response.status === 201) {
        setAddSuccessMessage('Service added successfully');
        setTimeout(() => setAddSuccessMessage(''), 5000);
        fetchData3();
      }
    } catch (error) {
      console.error('Error adding service:', error);
      setAddError('Failed to add service. Please try again.');
      setTimeout(() => setAddError(''), 5000);
    }
  };
  

  const deleteIcon = (id) => {
    setDeleteIconId(id); // Set the ID of the service to be deleted
    setShowDeletePopup(true); // Show the confirmation modal
  };
  
  const handleDeleteService = async () => {
    try {
      const response = await axios.delete(`https://backend.kbascontracting.com/ServicesSection/delete/${deleteIconId}`);
      if (response.status === 200) {
        
        fetchData3(); // Reload or update services data
        setShowDeletePopup(false); // Close the modal after deletion
      }
    } catch (error) {
      console.error('Error deleting service:', error);
      setUpdateErrorMessage('Failed to delete service. Please try again.');
    }
  };
  
  


  const handleUpdateService = (service) => {
    setSelectedServiceId(service.id);
    setUpdatedServiceData({
      updatedTitle: service.title,
      updatedParagraph: service.paragraph,
      updatedIconImage: service.iconImage,
      updatedMainImage: service.mainImage,
    });
    setShowUpdateFormservicesData(true);
  };

  const handleServiceUpdate1 = async (e) => {
    e.preventDefault();

    if (!updatedServiceData.updatedTitle.trim() || !updatedServiceData.updatedParagraph.trim()) {
      setUpdateErrorMessage('Title and Paragraph are required.');
      setTimeout(() => {
        setUpdateErrorMessage('');
      }, 5000);
      return;
    }

  const formData = new FormData();
  formData.append('title', updatedServiceData.updatedTitle);
  formData.append('paragraph', updatedServiceData.updatedParagraph);

  if (updatedServiceData.updatedIconImage instanceof File) {
    if (!isValidImageExtension(updatedServiceData.updatedIconImage.name)) {
        setUpdateErrorMessage("Invalid background image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
        setTimeout(() => setUpdateErrorMessage(''), 5000);
        return;
    }
    formData.append('iconImage', updatedServiceData.updatedIconImage);
}

// Validate and append mainImage
if (updatedServiceData.updatedMainImage instanceof File) {
    if (!isValidImageExtension(updatedServiceData.updatedMainImage.name)) {
        setUpdateErrorMessage("Invalid background image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
        setTimeout(() => setUpdateErrorMessage(''), 5000);
        return;
    }
    formData.append('mainImage', updatedServiceData.updatedMainImage);
}

    try {
      const response = await axios.put(
        `https://backend.kbascontracting.com/ServicesSection/update/${selectedServiceId}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      if (response.status === 200) {
        fetchData3();
        setUpdatedServiceData({
          updatedTitle: '',
          updatedParagraph: '',
          updatedIconImage: null,
          updatedMainImage: null,
        });
        setSelectedServiceId(null);
        setSuccessMessage('Service updated successfully');
        setUpdateErrorMessage('');
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);
      }
    } catch (error) {
      console.error('Error updating service:', error);
      setUpdateErrorMessage('Failed to update service. Please try again.');
    }
  };

  useEffect(() => {
    fetchData3();
  }, [showUpdateFormservicesData]);

  const handleIconImageChange = (e) => {
    const file = e.target.files[0];
    setUpdatedServiceData({ ...updatedServiceData, updatedIconImage: file });
  };

  const handleMainImageChange = (e) => {
    const file = e.target.files[0];
    setUpdatedServiceData({ ...updatedServiceData, updatedMainImage: file });
  };
  useEffect(() => {
    if (showUpdateFormservicesData) {
      // Assuming you want to focus on the 'name' input field when editing
      const nameInputField = document.getElementById('edit-navbar-name');
      if (nameInputField) {
        nameInputField.focus();
      }
    }
  }, [showUpdateFormservicesData]);





  const fetchData2 = async () => {
    try {
      const response = await axios.get("https://backend.kbascontracting.com/Services/list");
      if (response.data.success) {
        setServices(response.data.data);
      } else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setError("Error fetching data. Please try again later.");
    }
  };
  

  

  const handleUpdate2 = (service) => {
    setSelectedServiceId(service.id);
    setUpdateService({
      title: service.title,
      paragraph: service.paragraph,
    });
    setShowUpdateFormservices(true);
  };

  const handleServiceUpdate = async (e) => {
    e.preventDefault();

    if (!updateService.title.trim() || !updateService.paragraph.trim()) {
      setUpdateErrorMessage("Title and Paragraph are required.");
      setTimeout(() => {
        setUpdateErrorMessage("");
      }, 5000);
      return;
    }

    try {
      const response = await axios.put(
        `https://backend.kbascontracting.com/Services/update/${selectedServiceId}`,
        updateService
      );

      if (response.status === 200) {
        console.log("Service updated successfully:", response.data);
        fetchData2();
        setUpdateService({ title: "", paragraph: "" });
        setSelectedServiceId(null);
        setSuccessMessage("Service updated successfully");
        setUpdateErrorMessage("");
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      }
    } catch (error) {
      console.error("Error updating service:", error);
      setUpdateErrorMessage("Failed to update service. Please try again.");
    }
  };

  useEffect(() => {
    fetchData2();
  }, [showUpdateFormservices]);


  useEffect(() => {
    if (showUpdateFormservices) {
      // Assuming you want to focus on the 'name' input field when editing
      const nameInputField = document.getElementById('edit-navbar-name');
      if (nameInputField) {
        nameInputField.focus();
      }
    }
  }, [showUpdateFormservices]);


  
  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };
  

  
  
  

  

  
  
  
  
  

  const fetchData1 = async () => {
    setLoading(true);
    try {
      // Fetch feature section data
      const response = await axios.get("https://backend.kbascontracting.com/featureSection/list");
      if (response.data.success) {
        setInfoBarevents(response.data.data);
        setInfoBarCount(response.data.data.length);
      } else {
        console.error("Failed to fetch feature sections:", response.data.message);
      }
  
      // Fetch background image
      const bgImageResponse = await axios.get("https://backend.kbascontracting.com/backgroundImage/list");
      if (bgImageResponse.data.success) {
        setBackgroundImage(bgImageResponse.data.data.path); // Accessing the path directly
      } else {
        console.error("Failed to fetch background image:", bgImageResponse.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data. Please try again later.");
    }
    setLoading(false);
  };
  
  


  
  const handleBackgroundImageUpdate = () => {
    setShowBackgroundImageUpdateForm(true);
  };
  
  

  const handleUpdate1 = (event) => {
    if (event.id) {
      // For feature sections
      setSelectedEventId(event.id);
      setUpdateEvent({
        title: event.title,
        paragraph: event.paragraph,
        iconImage: event.iconImage // Prepare to update the icon image
      });
      setShowUpdateForminfoBar(true);
    } else {
      // For background image
      setNewBackgroundImage(null);
      setShowBackgroundImageUpdateForm(true);
    }
  };

  const handleBackgroundImageChange = (e) => {
    if (e.target.files[0]) {
      setNewBackgroundImage(e.target.files[0]);
    }
  };
  
  


  
  const handleEventUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (selectedEventId) {
        // Update feature section
        formData.append('title', updateEvent.title);
        formData.append('paragraph', updateEvent.paragraph);

        // Add iconImage if it's selected

        if (updateEvent.iconImage && updateEvent.iconImage instanceof File) {
          // Only validate the file extension if iconImage is a File object
          if (!isValidImageExtension(updateEvent.iconImage.name)) {
              setUpdateError("Invalid icon image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
              setTimeout(() => setUpdateError(""), 5000);
              return;
          }
          formData.append('iconImage', updateEvent.iconImage);
      }

        try {
            const response = await axios.put(`https://backend.kbascontracting.com/FeatureSection/update/${selectedEventId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Handle response
            console.log("Feature section updated successfully:", response.data);
            fetchData1(); // Refresh event list
            setUpdateSuccessMessage("Feature section updated successfully");
            setTimeout(() => setUpdateSuccessMessage(""), 5000);

            // Reset form fields and state related to updating a feature section
            setUpdateEvent({ title: "", paragraph: "", iconImage: null });
            setSelectedEventId(null);
            setShowUpdateForminfoBar(false);
        } catch (error) {
            console.error("Error updating feature section:", error);
            setUpdateError("Error updating feature section");
            setTimeout(() => setUpdateError(""), 5000);
        }
    } else {
        // Update background image
        if (newBackgroundImage) {
            if (!isValidImageExtension(newBackgroundImage.name)) {
                setUpdateError("Invalid image file extension for background image. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
                setTimeout(() => setUpdateError(""), 5000);
                return;
            }

            formData.append('image', newBackgroundImage);

            try {
                const response = await axios.put(`https://backend.kbascontracting.com/backgroundImage/update/1`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                // Fetch the updated background image URL from the server
                const updatedBgImageResponse = await axios.get("https://backend.kbascontracting.com/backgroundImage/list");
                if (updatedBgImageResponse.data.success) {
                    setBackgroundImage(updatedBgImageResponse.data.data.path);
                }

                setNewBackgroundImage(null);
                setShowBackgroundImageUpdateForm(false);
                setUpdateSuccessMessage("Background image updated successfully");
                setTimeout(() => setUpdateSuccessMessage(""), 5000);
            } catch (error) {
                console.error("Error updating background image:", error);
                setUpdateError("Error updating background image");
                setTimeout(() => setUpdateError(""), 5000);
            }
        } else {
            setUpdateError("Invalid image file extension for background image");
            setTimeout(() => setUpdateError(""), 5000);
        }
    }
};


  
  
  
  
  


  useEffect(() => {
    fetchData1();
  }, []);

  useEffect(() => {
    if (showUpdateForminfoBar) {
      // Assuming you want to focus on the 'name' input field when editing
      const nameInputField = document.getElementById('edit-navbar-name');
      if (nameInputField) {
        nameInputField.focus();
      }
    }
  }, [showUpdateForminfoBar]);


  const fetchData = async () => {
  try {
    const response = await axios.get("https://backend.kbascontracting.com/FullImage/list");
    if (response.data && response.data.success) {
      // Assuming you want to store it as an array
      setPolicies(response.data.data);
      setpolicies1Count(1);
    } else {
      console.error("Unexpected response format:", response.data);
      // Handle this situation appropriately
    }
  } catch (error) {
    console.error(`Error getting policies: ${error}`);
    setError('Error fetching policies. Please try again later.');
  } finally {
    setLoading(false);
  }
};

  


const handleUpdate = (image) => {
  setSelectedPolicyId(image.id);
  setUpdatePolicy({
    path: image.path, // Store the current image path
    file: null,      // Reset the file input
  });
  setShowUpdateForm(true);
};


const handleFileChange = (e) => {
  setUpdatePolicy({ ...updatePolicy, file: e.target.files[0] });
};

const handleImagePathUpdate = async (e) => {
  e.preventDefault();

  if (!updatePolicy.file) {
    setUpdateErrorMessage("An image file is required.");
    setTimeout(() => {
      setUpdateErrorMessage("");
    }, 5000);
    return;
  }

    // Validate the file extension
    if (!isValidImageExtension(updatePolicy.file.name)) {
      setUpdateErrorMessage("Invalid file extension. Please upload .WEBP, .GIF, .PNG, .JPG, or .JPEG");
      setTimeout(() => {
        setUpdateErrorMessage("");
      }, 5000);
      return;
    }

  const formData = new FormData();
  formData.append('image', updatePolicy.file);

  try {
    const response = await axios.put(
      `https://backend.kbascontracting.com/fullimage/update/${selectedPolicyId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    if (response.status === 200) {
      console.log('Image updated successfully:', response.data);
      fetchData();
      setUpdatePolicy({ file: null });
      setSelectedPolicyId(null);
      setSuccessMessage("Image updated successfully");
      setTimeout(() => {
        setSuccessMessage("");
      }, 5000);
    }
  } catch (error) {
    console.error('Error updating image:', error);
    setUpdateErrorMessage("Failed to update image. Please try again.");
  }
};
  

  
  useEffect(() => {
    fetchData();
    if (showUpdateForm) {
      // Focus on the input field when the form is shown
      const inputField = document.getElementById('edit-navbar-name');
      if (inputField) {
        inputField.focus();
      }
    }
  }, [showUpdateForm]);

  return (
    <>
    <div className="content">
      <div >
  

        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Home Page Image</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Image</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  {policies.map((policy) => (
    <tr key={policy.id}>
      <td>
        <img src={`https://backend.kbascontracting.com/${policy.path}`} alt="image" style={{ width: '250px', height: 'auto' }} />
      </td>
      <td>
        <Button color="success" onClick={() => handleUpdate(policy)}>Update</Button>
      </td>
    </tr>
  ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {showUpdateForm && (
  <div className="content mt-5">
    <Form onSubmit={handleImagePathUpdate}>
      <Row>
        <Col md="12">
          <FormGroup>
            {updatePolicy.path && (
              <div>
                <label>Current Image:</label>
                <div>
                  <img src={`https://backend.kbascontracting.com/${updatePolicy.path}`}alt="Current" style={{ width: '250px', height: 'auto' }} />
                </div>
              </div>
            )}
            <label className="mt-3">Upload New Image:</label>
            <Input
              type="file"
              name="image"
              id="edit-navbar-name"
              onChange={handleFileChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Button type="submit" color="primary">Update Image</Button>
          <Button color="secondary" onClick={() => setShowUpdateForm(false)}>Cancel</Button>
          {successMessage && <div className="alert alert-success"> {successMessage} </div>}
          {updateErrorMessage &&  <div style={{ color: 'red', marginTop: '10px' }}> {updateErrorMessage} </div>}
        </Col>
      </Row>
    </Form>
  </div>
)}

<div className="content">

{addError && (
  <div style={{ color: 'red', marginBottom: '10px' }}>
    {addError}
  </div>
)}

<div className="content">
        {/* ... Your JSX code ... */}

      </div>
      <div className="content">
  <Row>
    <Col md="12">
      {/* Table for Feature Section */}
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Feature Section</CardTitle>
        </CardHeader>
        <CardBody>
          <Table responsive>
            <thead className="text-primary">
              <tr>
                <th>Icon</th>
                <th>Title</th>
                <th>Paragraph</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {infoBarevents.map((event, index) => (
                <tr key={index}>
                  <td>
                    <img src={`https://backend.kbascontracting.com/${event.iconImage}`} alt="Icon" style={{ width: '40px' }} />
                  </td>
                  <td>{event.title}</td>
                  <td>{event.paragraph}</td>
                  <td>
                    <Button color="success" onClick={() => handleUpdate1(event)}>Update</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        

          {showUpdateForminfoBar && (
  <div className="content">
    <Form onSubmit={handleEventUpdate}>
      <Row>
        <Col md="7">
          <FormGroup>
            <label>Title:</label>
            <Input
              type="text"
              name="title"
              value={updateEvent.title}
              
              onChange={(e) => setUpdateEvent({ ...updateEvent, title: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Paragraph:</label>
            <Input
              type="text"
              name="paragraph"
              value={updateEvent.paragraph}
              id="edit-navbar-name"
              onChange={(e) => setUpdateEvent({ ...updateEvent, paragraph: e.target.value })}
            />
          </FormGroup>
        </Col>
        
        <Col md="7">
        <FormGroup>
          {updateEvent.iconImage && (
            <div>
              <label>Current Icon Image:</label>
              <div>
                <img src={`https://backend.kbascontracting.com/${updateEvent.iconImage}`} alt="Current Icon" style={{ width: '100px', marginBottom: '10px' }} />
              </div>
            </div>
          )}
          <label className="mt-3">Upload New Icon Image:</label>
          <Input
            type="file"
            name="iconImage"
            onChange={(e) => setUpdateEvent({ ...updateEvent, iconImage: e.target.files[0] })}
          />
        </FormGroup>
      </Col>
            </Row>
      {/* Submit and Cancel buttons */}
      <Row>
        <Col md="12">
          <div className="update ml-auto mr-auto">
            <Button className="btn-round" color="primary" type="submit">
              Update Event
            </Button>{" "}
            <Button
              className="btn-round"
              color="secondary"
              onClick={() => {
                setShowUpdateForminfoBar(false);
                setSelectedEventId(null);
                
              }}
            >
              Cancel
            </Button>
            {updateErrorMessage && (
    <div style={{ color: 'red', marginTop: '10px' }}>
        {updateErrorMessage}
    </div>
)}

            {updateError && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {updateError}
              </div>
            )}
            {updateSuccessMessage && (
              <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                {updateSuccessMessage}
              </div>
            )}
            {UpdateRequired && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {UpdateRequired}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  </div>
)}

      {/* Table for Background Image */}
      
          <Table responsive>
            <thead className="text-primary">
              <tr>
                <th>Background</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {backgroundImage && (
                    <img src={`https://backend.kbascontracting.com/${backgroundImage}`} alt="Background" style={{ width: '250px' }} />
                  )}
                </td>
                <td>
                <Button color="success" onClick={handleBackgroundImageUpdate}>Update</Button>                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  </Row>
</div>



      </div>

      {/* Display the update form when showUpdateForm is true */}


{showBackgroundImageUpdateForm && (
  <div className="content">
    <Form onSubmit={handleEventUpdate}>
      <Row>
        <Col md="12">
          <FormGroup>
            {backgroundImage && (
              <div>
                <label>Current Background Image:</label>
                <div>
                  <img src={`https://backend.kbascontracting.com/${backgroundImage}`} alt="Current Background" style={{ width: '250px', height: 'auto', marginBottom: '10px' }} />
                </div>
              </div>
            )}
            <label className="mt-3">Upload New Background Image:</label>
            <Input type="file" name="backgroundImage" onChange={handleBackgroundImageChange} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div className="update ml-auto mr-auto">
            <Button className="btn-round" color="primary" type="submit">Update Background Image</Button>{' '}
            <Button
              className="btn-round"
              color="secondary"
              onClick={() => setShowBackgroundImageUpdateForm(false)}
            >
              Cancel
            </Button>
            {updateErrorMessage &&  <div style={{ color: 'red', marginTop: '10px' }}> {updateErrorMessage} </div>}

          </div>
        </Col>
      </Row>
    </Form>
  </div>
)}


<div className="content">
      
        {/* ... Other components ... */}
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Services Section</CardTitle>
              </CardHeader>
              <CardBody>

              
             
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Title</th>
                      <th>Subtitle</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {services.map((service) => (
                      <tr key={service.id}>
                        <td>{service.title}</td>
                        <td>{service.paragraph}</td>
                        <td>
                          <Button color="success" onClick={() => handleUpdate2(service)}>Update</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
          



      {showUpdateFormservices && (
        <div className="content mt-5">
          <Form onSubmit={handleServiceUpdate}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>Title:</label>
                  <Input
                    type="text"
                    name="title"
                    value={updateService.title}
                    onChange={(e) => setUpdateService({ ...updateService, title: e.target.value })}
                  />
                </FormGroup>
              </Col>
              <Col md="7">
                <FormGroup>
                  <label>Subtitle:</label>
                  <Input
                    type="text"
                    name="paragraph"
                    value={updateService.paragraph}
                    id="edit-navbar-name"
                    onChange={(e) => setUpdateService({ ...updateService, paragraph: e.target.value })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="update ml-auto mr-auto">
                  <Button className="btn-round" color="primary" type="submit">Update Service</Button>{" "}
                  <Button
                    className="btn-round"
                    color="secondary"
                    onClick={() => setShowUpdateFormservices(false)}
                  >
                    Cancel
                  </Button>
                  {successMessage && <div className="alert alert-success">{successMessage}</div>}
                  {updateErrorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{updateErrorMessage}</div>}
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      )}
<CardBody>
<Form>
    <Row>
      <Col md="5">
        <FormGroup>
          <label>Title:</label>
          <Input type="text" name="title" id="newServiceTitle" />
        </FormGroup>
      </Col>
      <Col md="5">
        <FormGroup>
          <label>Paragraph:</label>
          <Input type="text" name="paragraph" id="newServiceParagraph" />
        </FormGroup>
      </Col>
      <Col md="8">
        <FormGroup>
          <label>Main Image:</label>
          <Input type="file" name="mainImage" id="newServiceMainImage" />
        </FormGroup>
      </Col>
      <Col md="8">
        <FormGroup>
          <label>Icon Image:</label>
          <Input type="file" name="iconImage" id="newServiceIconImage" />
        </FormGroup>
      </Col>
      <Col md="12">
        <Button className="btn-round" color="primary" onClick={handleAddService}>
          Add Service
        </Button>

                      {addSuccessMessage && (
                        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
                          {addSuccessMessage}
                        </div>
                      )}
                                       {addError && (
  <div style={{ color: 'red', marginTop: '10px' }}>
    {addError}
  </div>
)}
{ImageError && (
  <div style={{ color: 'red' }}>
    {ImageError}
  </div>
)}


                    </Col>
                  </Row>
                </Form>
              <Table responsive>
                <thead className="text-primary">
                  <tr>
                  <th>Image</th>
                  <th>icon</th>
                    <th>Title</th>
                    <th>Paragraph</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {servicesData.map((services1) => (
                      <tr key={services1.id}>
                        <td>
            <img src={`https://backend.kbascontracting.com/${services1.mainImage}`} alt="Main Image" style={{ maxWidth: '100px' }} />
          </td>
          <td>
            <img src={`https://backend.kbascontracting.com/${services1.iconImage}`} alt="Icon" style={{ maxWidth: '50px' }} />
          </td>
                        <td>{services1.title}</td>
                        <td>{services1.paragraph}</td>

                        <td>
                          <Button color="success" onClick={() => handleUpdateService(services1)}>
                            Update
                          </Button>
                        </td>

                        <td>
                        <Button color="danger" onClick={() => deleteIcon(services1.id)}>
                          Delete
                        </Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {showUpdateFormservicesData && (
        <div className="content mt-5">
          <Form onSubmit={handleServiceUpdate1}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>Title:</label>
                  <Input
                    type="text"
                    name="title"
                    value={updatedServiceData.updatedTitle}
                    onChange={(e) =>
                      setUpdatedServiceData({ ...updatedServiceData, updatedTitle: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="9">
                <FormGroup>
                  <label>Paragraph:</label>
                  <Input
                    type="text"
                    name="paragraph"
                    value={updatedServiceData.updatedParagraph}
                    onChange={(e) =>
                      setUpdatedServiceData({ ...updatedServiceData, updatedParagraph: e.target.value })
                    }
                    
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>Icon:</label>
                  <Input
                    type="file"
                    name="iconImage"
                    accept="image/*"
                    id="edit-navbar-name"
                    onChange={handleIconImageChange}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>Image:</label>
                  <Input
                    type="file"
                    name="mainImage"
                    accept="image/*"
                    onChange={handleMainImageChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="update ml-auto mr-auto">
                  <Button className="btn-round" color="primary" type="submit">
                    Update Service
                  </Button>{' '}
                  <Button className="btn-round" color="secondary" onClick={() => setShowUpdateFormservicesData(false)}>
                    Cancel
                  </Button>


                  {successMessage && <div className="alert alert-success">{successMessage}</div>}
                  {updateErrorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{updateErrorMessage}</div>}
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    
      
    </div>
    <div className="content">
      <div>
        {/* ... Other components ... */}
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Projects Section</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Title</th>
                      <th>Subtitle</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectItems.map((item) => (
                      <tr key={item.id}>
                        <td>{item.title}</td>
                        <td>{item.paragraph}</td>
                        <td>
                          <Button color="success" onClick={() => handleUpdateProjectItem(item)}>
                            Update
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
          



              {showUpdateFormproject && (
          <div className="content mt-5">
            <Form onSubmit={handleProjectItemUpdate}>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label>Title:</label>
                    <Input
                      type="text"
                      name="title"
                      value={updatedProjectItem.title}

                      onChange={(e) =>
                        setUpdatedProjectItem({ ...updatedProjectItem, title: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="7">
                  <FormGroup>
                    <label>Subtitle:</label>
                    <Input
                      type="text"
                      name="paragraph"
                      value={updatedProjectItem.paragraph}
                      id="edit-navbar-name"
                      onChange={(e) =>
                        setUpdatedProjectItem({ ...updatedProjectItem, paragraph: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div className="update ml-auto mr-auto">
                    <Button className="btn-round" color="primary" type="submit">
                      Update Project Item
                    </Button>{' '}
                    <Button
                      className="btn-round"
                      color="secondary"
                      onClick={() => setShowUpdateFormproject(false)}
                    >
                      Cancel
                    </Button>
                    {successMessage && <div className="alert alert-success">{successMessage}</div>}
                    {updateErrorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{updateErrorMessage}</div>}
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        )}
   <CardBody>

   <Form>
    <Row>
      <Col md="8">
        <FormGroup>
          <label>Image:</label>
          <Input
            type="file"
            name="image"
            id="newProjectImage"
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <Button
          className="btn-round"
          color="primary"
          onClick={handleAddProjectImage}
        >
          Add Project Image
        </Button>
        {addSuccessMessage && (
          <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
            {addSuccessMessage}
          </div>
        )}
        {addError && (
          <div style={{ color: 'red', marginTop: '10px' }}>
            {addError}
          </div>
        )}
        {ImageError && (
          <div style={{ color: 'red' }}>
            {ImageError}
          </div>
        )}
      </Col>
    </Row>
  </Form>

  <Table responsive>
    <thead className="text-primary">
      <tr>
        <th>Image</th> {/* Use "Image" for both main image and icon image */}
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
  {backgroundImagesData.map((backgroundImage) => (
    <tr key={backgroundImage.id}>
      <td>
        <img src={`https://backend.kbascontracting.com/${backgroundImage.path}`} alt="Image" style={{ maxWidth: '100px' }} />
      </td>
      <td>
        <Button color="success" onClick={() => handleUpdateBackgroundImage(backgroundImage)}>
          Update
        </Button>
      </td>
      <td>
      <Button color="danger" onClick={() => confirmDelete(backgroundImage.id)}>
  Delete
</Button>
      </td>
    </tr>
  ))}
</tbody>

  </Table>
</CardBody>
          </Card>
        </Col>
      </Row>

      {showUpdateFormselectedProject && (
  <div className="content mt-5">
    <Form onSubmit={handleBackgroundImageUpdate2}>
      <Row>

        <Col md="6">
          <FormGroup>
            <label>Image:</label>
            <Input
  type="file"
  name="image"
  accept="image/*"
  id="edit-navbar-name"
  onChange={(e) => setUpdatedBackgroundImageData({ ...updatedBackgroundImageData, path: e.target.files[0] })}
/>



          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div className="update ml-auto mr-auto">
            <Button className="btn-round" color="primary" type="submit">
              Update Background Image
            </Button>{' '}
            <Button className="btn-round" color="secondary" onClick={() => setShowUpdateFormselectedProject(false)}>
              Cancel
            </Button>
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            {updateErrorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{updateErrorMessage}</div>}
          </div>
        </Col>
      </Row>
    </Form>
  </div>
)}
    </div>
      
    </div>



    <div className="content">
      <div>
        {/* ... Other components ... */}
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">News Section</CardTitle>
              </CardHeader>
              <CardBody>
  <Table responsive>
    <thead className="text-primary">
      <tr>
        <th>Title</th>
        <th>Paragraph</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {newsData.map((newsItem) => (
        <tr key={newsItem.id}>
          <td>{newsItem.title}</td>
          <td>{newsItem.paragraph}</td>
          <td>
            <Button color="success" onClick={() => handleUpdateNewsItem(newsItem)}>
              Update
            </Button>
            
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
</CardBody>

          



{showUpdateFormnews && (
  <div className="content mt-5">
    <Form onSubmit={handleNewsItemUpdate}>
      <Row>
        <Col md="6">
          <FormGroup>
            <label>Title:</label>
            <Input
              type="text"
              name="title"
              value={updatedNewsItem.title}
              
              onChange={(e) => setUpdatedNewsItem({ ...updatedNewsItem, title: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Paragraph:</label>
            <Input
              type="text"
              name="paragraph"
              value={updatedNewsItem.paragraph}
              id="edit-navbar-name"
              onChange={(e) => setUpdatedNewsItem({ ...updatedNewsItem, paragraph: e.target.value })}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div className="update ml-auto mr-auto">
            <Button className="btn-round" color="primary" type="submit">
              Update News Item
            </Button>{" "}
            <Button className="btn-round" color="secondary" onClick={() => setShowUpdateFormnews(false)}>
              Cancel
            </Button>
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            {updateErrorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{updateErrorMessage}</div>}
          </div>
        </Col>
      </Row>
    </Form>
  </div>
)}

<CardBody>

<Form>
  <Row>
    <Col md="5">
      <FormGroup>
        <label>Title:</label>
        <Input type="text" name="title" id="newsTitle" />
      </FormGroup>
    </Col>

    <Col md="8">
      <FormGroup>
        <label>Paragraph:</label>
        <Input type="textarea" name="paragraph" id="newsParagraph" />
      </FormGroup>
    </Col>
    <Col md="8">
      <FormGroup>
        <label>Main Image:</label>
        <Input type="file" name="mainImage" id="newsMainImage" />
      </FormGroup>
    </Col>
    <Col md="12">
      <Button className="btn-round" color="primary" onClick={handleSubmit}>
        Add News Section
      </Button>
      {addSuccessMessage && (
          <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
            {addSuccessMessage}
          </div>
        )}
        {addError && (
          <div style={{ color: 'red', marginTop: '10px' }}>
            {addError}
          </div>
        )}
        {ImageError && (
          <div style={{ color: 'red' }}>
            {ImageError}
          </div>
        )}
    </Col>
  </Row>
</Form>



  <Table responsive>
    <thead className="text-primary">
      <tr>
        <th>Image</th>
        <th>Title</th>
        {/* <th>Subtitle</th> */}
        <th>Paragraph</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {newsSectionData.map((newsSection) => (
        <tr key={newsSection.id}>
          <td>
            <img
              src={`https://backend.kbascontracting.com/${newsSection.mainImage}`}
              alt="Main Image"
              style={{ maxWidth: '100px' }}
            />
          </td>
          <td>{newsSection.title}</td>
          {/* <td>{newsSection.subtitle}</td> */}
          <td>{newsSection.paragraph}</td>
          <td>
            <Button color="success" onClick={() => handleUpdateNewsSection(newsSection)}>
              Update
            </Button>
          </td>
          <td>
          <Button color="danger" onClick={() => promptDeleteNewsSection(newsSection.id)}>Delete</Button>

          </td>

        </tr>
      ))}
    </tbody>
  </Table>
</CardBody>

          </Card>
        </Col>
      </Row>

      {showUpdateFormnewsSection && (
  <div className="content mt-5">
    <Form onSubmit={handleNewsSectionUpdate}>
      <Row>
        <Col md="6">
          <FormGroup>
            <label>Image:</label>
            <Input
              type="file"
              name="mainImage"
              accept="image/*"
              id="edit-navbar-name"
              onChange={handleMainImageChange1}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label>Title:</label>
            <Input
              type="text"
              name="title"
              value={updatedNewsSectionData.title}
              onChange={(e) =>
                setUpdatedNewsSectionData({ ...updatedNewsSectionData, title: e.target.value })
              }
            />
          </FormGroup>
        </Col>
        
        <Col md="6">
          <FormGroup>
            <label>Paragraph:</label>
            <Input
              type="text"
              name="paragraph"
              value={updatedNewsSectionData.paragraph}
              onChange={(e) =>
                setUpdatedNewsSectionData({ ...updatedNewsSectionData, paragraph: e.target.value })
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div className="update ml-auto mr-auto">
            <Button className="btn-round" color="primary" type="submit">
              Update News Section
            </Button>{' '}
            <Button className="btn-round" color="secondary" onClick={() => setShowUpdateFormnewsSection(false)}>
              Cancel
            </Button>
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            {updateErrorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{updateErrorMessage}</div>}
          </div>
        </Col>
      </Row>
    </Form>
  </div>
)}

    </div>
      
    </div>

    <div className="content">
      <Row>
        <Col md="12">
          <Card>
          <CardHeader>
                <CardTitle tag="h4">Partners Section</CardTitle>
              </CardHeader>
            <CardBody>

            <Form onSubmit={handleAddImage}>
        <FormGroup>
          <label>Image:</label>
          <Input type="file" name="image" id="newImagePath" />
        </FormGroup>
        <Button className="btn-round" color="primary" type="submit">
          Add Image
        </Button>
        {addSuccessMessage && (
          <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
            {addSuccessMessage}
          </div>
        )}
        {ImageError && (
          <div style={{ color: 'red' }}>
        {ImageError}
          </div>
        )}
      </Form>

              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Image</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {backgroundImagesData1.map((backgroundImage) => (
                    <tr key={backgroundImage.id}>
                      <td>
                        <img
                          src={`https://backend.kbascontracting.com/${backgroundImage.path}`}
                          alt="Image"
                          style={{ maxWidth: '100px' }}
                        />
                      </td>
                      <td>
                        <Button
                          color="success"
                          onClick={() => handleUpdateBackgroundImage1(backgroundImage)}
                        >
                          Update
                        </Button>
                      </td>
                      <td>
                <Button color="danger" onClick={() => promptDeleteImage(backgroundImage.id)}>Delete</Button>
              </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {showUpdateFormImagesData && (
        <div className="content mt-5">
          <Form onSubmit={handleBackgroundImageUpdate1}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>Image:</label>
                  <Input
                    type="file"
                    name="image"
                    accept="image/*"
                    id="edit-navbar-name" 
                    onChange={(e) =>
                      setUpdatedBackgroundImageData({
                        ...updatedBackgroundImageData,
                        path: e.target.files[0],
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="update ml-auto mr-auto">
                  <Button className="btn-round" color="primary" type="submit">
                    Update Background Image
                  </Button>{' '}
                  <Button
                    className="btn-round"
                    color="secondary"
                    onClick={() => setShowUpdateFormImagesData(false)}
                  >
                    Cancel
                  </Button>
                  {successMessage && (
                    <div className="alert alert-success">{successMessage}</div>
                  )}
                  {updateErrorMessage && (
                    <div style={{ color: 'red', marginTop: '10px' }}>{updateErrorMessage}</div>
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
    <Modal isOpen={showDeletePopup} toggle={() => setShowDeletePopup(false)}>
  <ModalHeader toggle={() => setShowDeletePopup(false)}>Confirm Delete</ModalHeader>
  <ModalBody>
    Are you sure you want to delete this icon?
  </ModalBody>
  <ModalFooter>
    <Button color="danger" onClick={handleDeleteService}>Delete</Button>
    <Button color="secondary" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
  </ModalFooter>
</Modal>


<Modal isOpen={showDeletePopup} toggle={() => setShowDeletePopup(false)}>
  <ModalHeader toggle={() => setShowDeletePopup(false)}>Confirm Delete</ModalHeader>
  <ModalBody>
    Are you sure you want to delete this image?
  </ModalBody>
  <ModalFooter>
    <Button color="danger" onClick={handleDeleteProjectImage}>Delete</Button>
    <Button color="secondary" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
  </ModalFooter>
</Modal>


<Modal isOpen={showDeletePopup} toggle={() => setShowDeletePopup(false)}>
  <ModalHeader toggle={() => setShowDeletePopup(false)}>Confirm Delete</ModalHeader>
  <ModalBody>
    Are you sure you want to delete this news section?
  </ModalBody>
  <ModalFooter>
    <Button color="danger" onClick={handleDeleteNewsSection}>Delete</Button>
    <Button color="secondary" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
  </ModalFooter>
</Modal>


<Modal isOpen={showDeletePopup} toggle={() => setShowDeletePopup(false)}>
        <ModalHeader toggle={() => setShowDeletePopup(false)}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this image?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDeleteImage}>Delete</Button>
          <Button color="secondary" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

    </div>
    </> 

    
  );
}



export default PrivacyPolicies;


